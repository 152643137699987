import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Drawer,
  Button,
  Tooltip,
  Modal,
  Form,
  Input,
  Select,
  message,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  userRole,
  userTutorCityAccess,
  userStudentCityAccess,
  userShadowTutorCityAccess,
} from "../states/userRole";
import { citysList } from "../states/citys";
import axios from "../axios";
import dayjs from "dayjs";
import log from '../logger'; 

const { Option } = Select;

function Team() {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState({});
  const [role, setRole] = useAtom(userRole);
  const [tutorCityAccess, setTutorCityAccess] = useAtom(userTutorCityAccess);
  const [studentCityAccess, setStudentCityAccess] = useAtom(
    userStudentCityAccess
  );
  const [shadowTutorCityAccess, setShadowTutorCityAccess] = useAtom(
    userShadowTutorCityAccess
  );
  const [citys, setCitys] = useAtom(citysList);
  const [form] = Form.useForm();
  const [editForm, setEditForm] = useState(false);

  const fetchCreTeamData = () => {
    axios.get(`/v1/cre/teams`).then((res) => {
      setData(res.data.data);
    });
  };

  useEffect(() => {
    fetchCreTeamData();
  }, []);

  const columns = [
    {
      title: "Created At",
      dataIndex: "user_details",
      key: "created_at",
      width: 100,
      render: (_, record) =>
        dayjs(record.user_details.created_at).format("DD-MMM-YYYY"),
    },
    {
      title: "Name",
      dataIndex: "user_details",
      key: "name",
      width: 120,
      render: (_, record) => (
        <div style={{ cursor: "pointer" }}>
          <Tooltip
            placement="bottomLeft"
            title={record.user_details.first_name}
            onClick={() => {
              if (
                record.user_details.user_type === "Superviser" ||
                role === "Superadmin"
              ) {
                setVisible(true);
                setRowData(record);
              }
            }}
            style={{ cursor: "pointer" }}
          >
            {record.user_details.first_name}
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "user_details",
      key: "email",
      ellipsis: {
        showTitle: false,
      },
      width: 250,
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.user_details.email}>
          {record.user_details.email}
        </Tooltip>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "user_details",
      key: "phone",
      width: 130,
      render: (_, record) => record.user_details.phone,
    },
    {
      title: "User Type",
      dataIndex: "user_details",
      key: "user_type",
      width: 100,
      render: (_, record) => record.user_details.user_type,
    },
    {
      title: "Parents City Access",
      dataIndex: "access_details",
      key: "student",
      ellipsis: {
        showTitle: false,
      },
      width: 210,
      render: (_, record) => {
        const studentCitysIds = record?.access_details?.student?.split(",");
        return (
          <Tooltip
            placement="topLeft"
            title={Object.keys(citys)
              .filter((city_id) => studentCitysIds.includes(city_id))
              .map((city_id) => citys[city_id])
              .join(", ")}
          >
            {Object.keys(citys)
              .filter((city_id) => studentCitysIds.includes(city_id))
              .map((city_id) => citys[city_id])
              .join(", ")}
          </Tooltip>
        );
      },
    },
    {
      title: "Tutors City Access",
      dataIndex: "access_details",
      key: "tutor",
      ellipsis: {
        showTitle: false,
      },
      width: 210,
      render: (_, record) => {
        const tutorCitysIds = record?.access_details?.tutor?.split(",");
        return (
          <Tooltip
            placement="topLeft"
            title={Object.keys(citys)
              .filter((city_id) => tutorCitysIds.includes(city_id))
              .map((city_id) => citys[city_id])
              .join(", ")}
          >
            {Object.keys(citys)
              .filter((city_id) => tutorCitysIds.includes(city_id))
              .map((city_id) => citys[city_id])
              .join(", ")}
          </Tooltip>
        );
      },
    },
    {
      title: "Shadow Tutor City Access",
      dataIndex: "access_details",
      key: "shadow_tutor",
      ellipsis: {
        showTitle: false,
      },
      width: 150,
      render: (_, record) => {
        const shadowTutorCitysIds =
          record?.access_details?.shadow_tutor?.split(",");
        return (
          <Tooltip
            placement="topLeft"
            title={Object.keys(citys)
              .filter((city_id) => shadowTutorCitysIds.includes(city_id))
              .map((city_id) => citys[city_id])
              .join(", ")}
          >
            {Object.keys(citys)
              .filter((city_id) => shadowTutorCitysIds.includes(city_id))
              .map((city_id) => citys[city_id])
              .join(", ")}
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      {visible && (
        <Drawer
          title={`Edit Details for ${rowData?.user_details?.first_name}`}
          width={720}
          onClose={() => setVisible(false)}
          open={visible}
        >
          <Form
            onFinish={(values) => {
              const accessDetails = {
                student: values.student,
                tutor: values.tutor,
                shadow_tutor: values.shadow_tutor,
              };
              const userDetails = {
                first_name: values.first_name,
                // email: values.email,
                phone: values.phone,
                // user_type: values.user_type,
                is_active: values.is_active,
              };
              if (role === "Superadmin") {
                userDetails.user_type = values.user_type;
              }
              const data = {
                user_details: userDetails,
                access_details: accessDetails,
              };

              axios
                .put(`/v1/cre/teams/${rowData?.user_details?.id}`, data)
                .then((res) => {
                  log.debug(res.data);
                  const { is_success, data, error } = res.data;
                  if (!is_success) return message.error(error);
                  message.success(
                    rowData?.user_details?.first_name +
                      " details updated successfully"
                  );
                  form.setFields([
                    "first_name",
                    "email",
                    "phone",
                    "user_type",
                    "is_active",
                    "student",
                    "tutor",
                    "shadow_tutor",
                  ]);
                  form.resetFields();
                  form.disable = true;
                  setVisible(false);
                  setRowData({});
                  fetchCreTeamData();
                })
                .catch((error) => {
                  message.error("Error updating user details");
                });
              setEditForm(false);
            }}
            initialValues={{
              first_name: rowData?.user_details?.first_name,
              email: rowData?.user_details?.email,
              phone: rowData?.user_details?.phone,
              user_type: rowData?.user_details?.user_type,
              is_active: rowData?.user_details?.is_active,
              student: rowData?.access_details?.student?.split(","),
              tutor: rowData?.access_details?.tutor?.split(","),
              shadow_tutor: rowData?.access_details?.shadow_tutor?.split(","),
            }}
            disabled={!editForm}
          >
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input first name!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              disabled={true}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input email!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input phone number!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="User Type"
              name="user_type"
              disabled={role !== "Superadmin" || !editForm}
              rules={[
                {
                  required: true,
                  message: "Please input user type!",
                },
              ]}
            >
              <Select disabled={role !== "Superadmin" || !editForm}>
                <Option value="Admin">Admin</Option>
                <Option value="Superviser">Superviser</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Is Active"
              name="is_active"
              rules={[
                {
                  required: true,
                  message: "Please select activity status!",
                },
              ]}
            >
              <Select>
                <Option value={1}>Active</Option>
                <Option value={0}>Inactive</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Student Access" name="student">
              <Select mode="multiple" style={{ width: "100%" }}>
                {role != "Superadmin"
                  ? studentCityAccess?.map((city_id) => (
                      <Option key={city_id} value={city_id}>
                        {citys[city_id]}
                      </Option>
                    ))
                  : Object.keys(citys).map((city_id) => (
                      <Option key={city_id} value={city_id}>
                        {citys[city_id]}
                      </Option>
                    ))}
              </Select>
            </Form.Item>

            <Form.Item label="Tutor Access" name="tutor">
              <Select mode="multiple" style={{ width: "100%" }}>
                {role != "Superadmin"
                  ? tutorCityAccess?.map((city_id) => (
                      <Option key={city_id} value={city_id}>
                        {citys[city_id]}
                      </Option>
                    ))
                  : Object.keys(citys).map((city_id) => (
                      <Option key={city_id} value={city_id}>
                        {citys[city_id]}
                      </Option>
                    ))}
              </Select>
            </Form.Item>

            <Form.Item label="Shadow Tutor Access" name="shadow_tutor">
              <Select mode="multiple" style={{ width: "100%" }}>
                {role != "Superadmin"
                  ? shadowTutorCityAccess?.map((city_id) => (
                      <Option key={city_id} value={city_id}>
                        {citys[city_id]}
                      </Option>
                    ))
                  : Object.keys(citys).map((city_id) => (
                      <Option key={city_id} value={city_id}>
                        {citys[city_id]}
                      </Option>
                    ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Space direction="horizontal">
                <Button
                  type="primary"
                  disabled={editForm}
                  onClick={() => {
                    setEditForm(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => {
                    setEditForm(false);
                    setVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  type="primary"
                  disabled={editForm}
                  onClick={() => {
                    Modal.confirm({
                      title: `Are you sure you want to reset password for ${rowData?.user_details?.first_name}?`,
                      content:
                        "This will send an email to user with new password",
                      onOk() {
                        axios
                          .post(
                            `/v1/cre/teams/password-reset/${rowData?.user_details?.id}`
                          )
                          .then((res) => {
                            const { is_success, data, error } = res.data;
                            if (!is_success) {
                              message.error(error);
                              return;
                            }
                            message.success({
                              content: (
                                <div>
                                  Password: <b>{data}</b>{" "}
                                  <CopyOutlined
                                    className="copy"
                                    onClick={() =>
                                      navigator.clipboard.writeText(data)
                                    }
                                  />
                                  <Button
                                    type="link"
                                    size="small"
                                    style={{
                                      padding: "3px",
                                      marginLeft: "15px",
                                    }}
                                    onClick={() => message.destroy()}
                                  >
                                    X
                                  </Button>
                                </div>
                              ),
                              // duration: 0,
                            });
                          })
                          .catch((error) => {
                            message.error("Error resetting password");
                          });
                      },
                    });
                  }}
                >
                  Reset Password
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Drawer>
      )}
      <Table
        columns={columns}
        dataSource={data}
        style={{ margin: "20px auto", padding: "10px" }}
      />
    </div>
  );
}

export default Team;
