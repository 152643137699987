import React, { useState } from "react";
import {
  Form,
  Flex,
  Input,
  Select,
  Button,
  Space,
  Divider,
  Row,
  Col,
  message,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";

import { citysList } from "../states/citys";
// import { tutorsList } from '../states/tutors'

import {
  userRole,
  userTutorCityAccess,
  userStudentCityAccess,
  userShadowTutorCityAccess,
} from "../states/userRole";
import { useAtom } from "jotai";
import axios from "../axios";
import log from '../logger'; 

const { Option } = Select;

function CreateCre() {
  const [form] = Form.useForm();
  const [citys, setCitys] = useAtom(citysList);
  const [role, setRole] = useAtom(userRole);
  const [tutorCityAccess, setTutorCityAccess] = useAtom(userTutorCityAccess);
  const [studentCityAccess, setStudentCityAccess] = useAtom(
    userStudentCityAccess
  );
  const [shadowTutorCityAccess, setShadowTutorCityAccess] = useAtom(
    userShadowTutorCityAccess
  );
  const onFinish = async (values) => {
    axios
      .post("/v1/cre/user", values)
      .then((res) => {
        const { is_success, data, error } = res.data;
        if (is_success) {
          message.success({
            content: (
              <div>
                Password: <b>{data}</b>{" "}
                <CopyOutlined
                  className="copy"
                  onClick={() => navigator.clipboard.writeText(data)}
                />
                <Button
                  type="link"
                  size="small"
                  style={{ padding: "3px", marginLeft: "15px" }}
                  onClick={() => message.destroy()}
                >
                  X
                </Button>
              </div>
            ),
            // duration: 0,
          });
          form.resetFields();
        } else {
          log.debug(error);
          message.error(error, 5, () => message.destroy(), {
            duration: 0,
          });
        }
      })
      .catch((err) => {
        log.debug(err);
      });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="nest-messages"
      style={{
        width: "95%",
        margin: "10px auto",
      }}
      onFinish={onFinish}
      initialValues={{ user_type: "Superviser" }}
    >
      <Divider>User Details</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={["user_details", "email"]}
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["user_details", "first_name"]}
            label="Full Name"
            rules={[
              {
                required: true,
                message: "Please input your Full Name!",
              },
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["user_details", "phone"]}
            label="Phone No"
            rules={[
              {
                required: true,
                message: "Please input your Phone No!",
              },
            ]}
          >
            <Input placeholder="Phone No" type="number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["user_details", "user_type"]}
            label="Role"
            rules={[
              {
                required: true,
                message: "Please select your role!",
              },
            ]}
          >
            <Select placeholder="Select Role">
              {role === "Superadmin" && (
                <Select.Option value="Admin">Admin</Select.Option>
              )}
              <Select.Option value="Superviser">Superviser</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider>Access Details</Divider>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={["access_details", "student"]}
            label="Parents City"
            rules={[
              {
                required: true,
                message: "Please select the Parent City!",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              placeholder="Select City"
            >
              {role != "Superadmin"
                ? studentCityAccess?.map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))
                : Object.keys(citys).map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["access_details", "tutor"]}
            label="Tutors City"
            rules={[
              {
                required: true,
                message: "Please select atleast one Tutor!",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              placeholder="Select City"
            >
              {role != "Superadmin"
                ? tutorCityAccess?.map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))
                : Object.keys(citys).map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["access_details", "shadow_tutor"]}
            label="Shadow Tutors City"
          >
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              placeholder="Select City"
            >
              {role != "Superadmin"
                ? shadowTutorCityAccess?.map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))
                : Object.keys(citys).map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Flex justify="center" align="center">
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button htmlType="button" onClick={() => form.resetFields()}>
              Reset
            </Button>
          </Space>
        </Flex>
      </Form.Item>
    </Form>
  );
}

export default CreateCre;
