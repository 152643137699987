import axios from 'axios';
import { API_BASE_URL } from './config';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Set your API base URL
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('homeshiksha-cre-jwt'); // Retrieve your JWT token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
