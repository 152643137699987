import React, { useEffect, useState } from "react";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  Tooltip,
  DatePicker,
  Tag,
  Flex,
  Row,
  Form,
  Select,
  Drawer,
  Col,
  Badge,
  Switch,
} from "antd";
import dayjs from "dayjs";
import StudentDrawer from "../components/StudentDrawer";
import { useAtom } from "jotai";
import { studentList } from "../states/student";
import { userRole, userStudentCityAccess } from "../states/userRole";
import { citysList } from "../states/citys";
import Autocomplete from "react-google-autocomplete";
import {
  academicLevels,
  courseCategories,
  teachingTypes,
} from "../constants/tutorConstants";
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from "../axios.js";
import { DATE_FILTER_TYPE, DATE_RANGE_MAP, getDateRange } from "../components/constants.js";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const STUDENT_STATUS_COLORS = {
  pending: "red",
  demo: "purple",
  assigned: "green",
  Processing: "cyan",
  confirmed: "blue",
  new: "cyan",
  in_progress: "orange",
  escalate: "magenta",
  inactive: "gray",
  prospect: "geekblue",
  Not_interested: "volcano",
  Interested: "orange",
  Verified: "geekblue",
};

const dateRangeMap = {
  today: {
    fromDate: dayjs().startOf("day").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("day").format("YYYY-MM-DD"),
  },
  yesterday: {
    fromDate: dayjs().subtract(1, "day").startOf("day").format("YYYY-MM-DD"),
    toDate: dayjs().subtract(1, "day").endOf("day").format("YYYY-MM-DD"),
  },
  this_week: {
    fromDate: dayjs().startOf("week").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("week").format("YYYY-MM-DD"),
  },
  last_week: {
    fromDate: dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
    toDate: dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
  },
  this_month: {
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  },
  last_month: {
    fromDate: dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    toDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  },
};

const Student = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchText, setSearchText] = useState();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [searchFieldType, setSearchFieldType] = useState("first_name");
  const [filterForm] = Form.useForm();
  const [geoSearchData, setGeoSearchData] = useState(null);
  const [filtersCount, setFiltersCount] = useState(0);
  const [citys, setCitys] = useAtom(citysList);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useAtom(studentList);
  const [open, setOpen] = useState(false);
  const [role, setRole] = useAtom(userRole);
  const [studentCityAccess, setStudentCityAccess] = useAtom(
    userStudentCityAccess
  );
  const [activeRowId, setActiveRowId] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 200,
  });

  const [globalFilter, setGlobalFilter] = useState(null);
  const [dateRangeType, setDateRangeType] = useState("relative");
  const [dateRange, setDateRange] = useState([]);
  const [presetRange, setPresetRange] = useState("");

  const showDrawer = (activeRowId) => {
    setActiveRowId(activeRowId);
    setOpen(true);
  };

  const updateURLWithFilters = (filters) => {
    const searchParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        if (Array.isArray(value)) {
          searchParams.append(`filter[${key}]`, value.join(","));
        } else {
          searchParams.append(`filter[${key}]`, value);
        }
      }
    });
    if (filters.page) searchParams.append("page", filters.page);
    if (filters.limit) searchParams.append("limit", filters.limit);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const applyFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const filters = {};
    for (let [key, value] of searchParams.entries()) {
      if (key.startsWith('filter[')) {
        const matches = key.match(/filter\[(.*?)\]/);
        if (matches) {
          const [, filterKey] = matches;
          const splitValues = value.split(',');
          filters[filterKey] = Array.isArray(splitValues) && splitValues.length > 1 ? splitValues : value;
        }
      } else {
        filters[key] = value;
      }
    }
    
    filterForm.setFieldsValue(filters);

    if (filters.searchFieldType) {
      setSearchFieldType(filters.searchFieldType);
      setSearchText(filters.searchText);
      if (filters.searchFieldType === "address") {
        setGeoSearchData(filters.searchText);
      }
    }

    // Date Filter Logic
    let fromDate = "";
    let toDate = "";

    // Relative Date Handling
    if (
      dateRangeType === DATE_FILTER_TYPE['relative'] &&
      presetRange &&
      DATE_RANGE_MAP[presetRange]
    ) {
      fromDate = DATE_RANGE_MAP[presetRange].fromDate;
      toDate = DATE_RANGE_MAP[presetRange].toDate;
    }
    // Absolute Date Handling
    else if (dateRangeType === DATE_FILTER_TYPE['absolute'] && dateRange.length === 2) {
      const [start, end] = dateRange;

      // Check if start date is valid
      if (start.isAfter(end) || start.isSame(end)) {
        console.error("Start date cannot be the same or after end date.");
        return;
      }
      fromDate = getDateRange(start);
      toDate = getDateRange(end);
    }

    // Add fromDate and toDate to filters if they exist
    if (fromDate) {
      filters.fromDate = fromDate;
    }
    if (toDate) {
      filters.toDate = toDate;
    }

    const filterCount = Object.keys(filters).filter(
      (key) =>
        ![
          "searchText",
          "searchFieldType",
          "page",
          "limit",
          "fromDate",
          "toDate",
        ].includes(key)
    ).length;
    setFiltersCount(filterCount);

    if (Object.keys(filters).length > 0) {
      fetchFilteredData(filters, filters.page, filters.limit);
    } else {
      fetchData(pagination.current, pagination.pageSize);
    }
  };

  useEffect(() => {
    applyFiltersFromURL();
  }, [location.search, dateRange, dateRangeType, presetRange]);

  const fetchData = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/v1/cre/student", {
        params: {
          page: page,
          limit: pageSize,
        },
      });
      if (
        response.data.is_success &&
        response.data.data &&
        response.data.data.Result
      ) {
        setData(response.data.data.Result);
        setPagination({
          ...pagination,
          current: page,
          pageSize: pageSize,
          total: response.data.data.total || 0,
        });
      } else {
        setData([]);
        setPagination({
          ...pagination,
          current: page,
          pageSize: pageSize,
          total: 0,
        });
      }
    } catch (error) {
      //console.error("Error fetching data:", error);
      setData([]);
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
        total: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchFilteredData = async (filter, page = 1, limit = 10) => {
    setGlobalFilter(filter);
    setData([]);
    setLoading(true);

    const params = new URLSearchParams();

    // Handle filter parameters
    Object.entries(filter).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          params.append(`filter[${key}][${index}]`, item);
        });
      } else if (value !== undefined && value !== null && value !== "") {
        params.append(`filter[${key}]`, value);
      }
    });

    // Add pagination parameters
    params.append("page", page.toString());
    params.append("limit", limit.toString());

    updateURLWithFilters({ ...filter, page, limit });

    try {
      const response = await axiosInstance.get(
        `/v1/cre/student?${params?.toString()}`
      );
      if (
        response.data.is_success &&
        response.data.data &&
        response.data.data.Result
      ) {
        setData(response.data.data.Result);
        setPagination({
          ...pagination,
          current: page,
          pageSize: limit,
          total: response.data.data.total || 0,
        });
      } else {
        setData([]);
        setPagination({
          ...pagination,
          current: page,
          pageSize: limit,
          total: 0,
        });
      }
    } catch (error) {
      //console.error("Error fetching filtered data:", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (!globalFilter) {
      fetchData(pagination.current, pagination.pageSize);
    } else {
      fetchFilteredData(globalFilter, pagination.current, pagination.pageSize);
    }
  };

  const handleDateRangeTypeChange = (checked) => {
    setDateRangeType(checked ? DATE_FILTER_TYPE['absolute'] : DATE_FILTER_TYPE['relative']);
    setDateRange([]);
    setPresetRange("today");
  };

  const handleDateChange = (dates) => {
    if (dates) {
      setDateRange(dates);
      setPresetRange("");
    } else {
      setDateRange([]);
      setPresetRange("");
    }
  };

  const handlePresetRangeChange = (value) => {
    setPresetRange(value);
    setDateRange([]);
  };

  const columns = [
    {
      title: "Deal ID",
      dataIndex: "id",
      key: "id",
      width: "70px",
      render: (_, record) => {
        return <>{record?.id}</>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width: "110px",
      render: (_, record) => {
        let date = record?.created_at?.split("T")[0];
        let color = "white";
        if (record?.profile_status === "Block") {
          color = "red";
        }
        if (record?.profile_status === "InActive") {
          color = "cyan";
        }
        date = date ? dayjs(date).format("Do MMM YYYY") : "";

        return (
          <Tag
            color={color}
            style={{
              width: "100%",
            }}
          >
            <Flex
              style={{
                color: "#262121",
                margin: "0px auto",
                minHeight: "30px",
                minWidth: `${date?.length * 9}px`,
              }}
              justify="center"
              align="center"
            >
              {date}
            </Flex>
          </Tag>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "110px",
      render: (_, record) => {
        let date = record?.updated_at?.split("T")[0];
        let color = "white";
        if (record?.profile_status === "Block") {
          color = "red";
        }
        if (record?.profile_status === "InActive") {
          color = "cyan";
        }
        date = date ? dayjs(date).format("Do MMM YYYY") : "";

        return (
          <Tag
            color={color}
            style={{
              width: "100%",
            }}
          >
            <Flex
              style={{
                color: "#262121",
                margin: "0px auto",
                minHeight: "30px",
                minWidth: `${date?.length * 9}px`,
              }}
              justify="center"
              align="center"
            >
              {date}
            </Flex>
          </Tag>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: "200px",
      ellipsis: true,

      render: (_, record) => {
        const name = record?.first_name + " " + record?.last_name;
        let color = "white";
        if (record?.profile_status === "Block") {
          color = "red";
        }
        if (record?.profile_status === "InActive") {
          color = "cyan";
        }

        return (
          <Tag
            color={color}
            style={{
              width: "100%",
            }}
          >
            <Flex
              align="center"
              style={{
                color: record?.is_paid ? "#59d559" : "#262121",
                fontWeight: record?.is_paid ? "bolder" : "",
                cursor: "pointer",
                textTransform: "capitalize",
                minHeight: "30px",
              }}
              onClick={() => showDrawer(record.id)}
            >
              <Tooltip title={name} placement="bottomLeft">
                <div style={{ minWidth: `${name?.length * 8}px` }}>
                  {name}
                  {record?.is_approved ? (
                    <img
                      src="./approved-icon.svg"
                      alt="tick mark"
                      height="12px"
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Tooltip>
            </Flex>
          </Tag>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "120px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "100px",
      render: (_, record) => {
        const tutorStatusColorMapper = {
          pending: "red",
          demo: "purple",
          assigned: "green",
          Processing: "cyan",
          confirmed: "blue",
          new: "cyan",
          in_progress: "orange",
          escalate: "magenta",
          inactive: "gray",
          prospect: "geekblue",
          Not_interested: "volcano",
        };
        let status = record?.status;
        if (status == "pending" && record?.advance_payment) {
          status = "Processing";
        }
        return (
          <div style={{ minWidth: `${status?.length * 8}px` }}>
            <Tag color={tutorStatusColorMapper[status]}>{status}</Tag>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "100px",
      render: (_, record) => {
        let status = record?.status;
        if (status === "pending" && record?.advance_payment) {
          status = "Processing";
        }
        return (
          <div style={{ minWidth: `${status?.length * 8}px` }}>
            <Tag color={STUDENT_STATUS_COLORS[status]}>{status}</Tag>
          </div>
        );
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: "100px",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 250,
      render: (_, record) => {
        const category = courseCategories[record?.category_id];
        return category;
      },
    },
    {
      title: "Requirements",
      dataIndex: "requirements",
      key: "requirements",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => {
        const requirements = record?.requirements
          ?.split("<-->")
          .map((area) => {
            if (area != "undefined") return area;
            return "";
          })
          .join(" ");
        return (
          <Tooltip placement="topLeft" title={requirements}>
            <div
              style={{
                padding: "5px",
              }}
            >
              {requirements}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      width: 130,
    },
    {
      title: "Tutor Gender Preference",
      dataIndex: "tutor_gender_preference",
      key: "tutor_gender_preference",
      width: 120,
    },
    {
      title: "How Many Days in a Week",
      dataIndex: "how_many_days_in_a_week",
      key: "how_many_days_in_a_week",
      width: 130,
    },
    {
      title: "Tution Type",
      dataIndex: "tution_type",
      key: "tution_type",
      width: 100,
    },
    {
      title: "City",
      dataIndex: "city_id",
      key: "city_id",
      width: "100px",
      render: (_, record) => {
        const cityName = citys[record.city_id];
        return (
          <div
            style={{
              width: `${cityName?.length * 10}px`,
            }}
          >
            {cityName}
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 250,
      ellipsis: {
        showTitle: true,
      },
      render: (_, record) => {
        const address = record?.address
          ?.split("<-->")
          .map((area) => {
            if (area != "undefined") return area;
            return "";
          })
          .join(" ");
        return (
          <Tooltip placement="topLeft" title={address}>
            <div
              style={{
                padding: "5px",
              }}
            >
              {address}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Flex
        style={{ margin: "15px auto" }}
        align="center"
        justify="space-between"
        direction="row"
      >
        <Flex
          align="center"
          style={{ width: "450px", justifyContent: "flex-start" }}
        >
          <div style={{ width: "120px", marginRight: "10px" }}>
            <Switch
              checkedChildren="Absolute"
              unCheckedChildren="Relative"
              checked={dateRangeType === "absolute"}
              onChange={handleDateRangeTypeChange}
            />
          </div>
          {dateRangeType === "absolute" ? (
            <RangePicker
              style={{ width: "300px" }}
              value={dateRange}
              onChange={handleDateChange}
              allowClear={false}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          ) : (
            <Select
              style={{ width: "300px" }}
              value={presetRange}
              onChange={handlePresetRangeChange}
            >
              <Option value="today">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="this_week">This Week</Option>
              <Option value="last_week">Last Week</Option>
              <Option value="this_month">This Month</Option>
              <Option value="last_month">Last Month</Option>
            </Select>
          )}
        </Flex>

        <Row align="middle">
          <Col style={{ margin: "auto 10px" }}>
            <Select
              placeholder="Select Type"
              style={{ width: 120, fontWeight: "bolder" }}
              value={searchFieldType}
              onChange={(e) => {
                setSearchFieldType(e);
                setSearchText(null);
              }}
              defaultValue={"name"}
            >
              <Option value="first_name">Name</Option>
              <Option value="phone">Phone</Option>
              <Option value="address">Address</Option>
              <Option value="userId">User Id</Option>
            </Select>
          </Col>

          {searchFieldType === "address" ? (
            <Flex style={{ margin: "auto 10px" }}>
              <Autocomplete
                apiKey={apiKey}
                style={{ width: "500px", paddingRight: "30px" }}
                className="ant-input css-dev-only-do-not-override-1uweeqc ant-input-outlined"
                onPlaceSelected={(place) => {
                  setSearchText(place.formatted_address);
                  let latitude = place.geometry.location.lat();
                  let longitude = place.geometry.location.lng();
                  setGeoSearchData(latitude + "," + longitude);
                }}
                options={{
                  types: [],
                  componentRestrictions: { country: "IN" },
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />

              <SearchOutlined
                style={{
                  cursor: "pointer",
                  marginLeft: "-29px",
                  padding: "5px",
                  zIndex: 1,
                  color: "rgba(0, 0, 0, 0.5)",
                  borderLeft: "1px solid #d9d9d9",
                }}
                onClick={() => {
                  if (searchText)
                    fetchFilteredData({
                      ...(globalFilter ? globalFilter : {}),
                      searchText: geoSearchData,
                      searchFieldType,
                    });
                }}
              />
            </Flex>
          ) : (
            <Search
              value={searchText}
              type={searchFieldType === "phone" ? "number" : "text"}
              maxLength={searchFieldType === "phone" ? 10 : undefined}
              style={{ width: 500 }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder={searchFieldType === "phone" ? "Phone" : "Search"}
              onSearch={() => {
                if (searchText)
                  fetchFilteredData({
                    ...(globalFilter ? globalFilter : {}),
                    searchText,
                    searchFieldType,
                  });
              }}
            />
          )}
        </Row>

        {searchFieldType !== "userId" && (
          <Col style={{ margin: "auto 10px", position: "relative" }}>
            <Badge
              count={filtersCount}
              style={{
                position: "absolute",
                top: "0px",
                cursor: "pointer",
                right: "8px",
                backgroundColor: "rgba(39, 65, 120, 1)",
              }}
              onClick={() => {
                setFilterDrawerOpen(true);
              }}
              overflowCount={99}
            >
              <FilterOutlined
                style={{ cursor: "pointer", margin: "auto 12px" }}
              />
            </Badge>
          </Col>
        )}

        <Col>
          <Button
            type="primary"
            style={{
              margin: "10px",
              color: "white",
              backgroundColor: "rgba(39, 65, 120, 1)",
            }}
            onClick={() => {
              setSearchText(null);
              setSearchFieldType("first_name");
              setGeoSearchData(null);
              fetchData(1, 10);
              setGlobalFilter(null);
              setFiltersCount(0);
              filterForm.resetFields();
              setDateRangeType("relative");
              setDateRange([]);
              setPresetRange("");
              navigate(location.pathname); // Clear URL parameters
            }}
          >
            Clear Filters
          </Button>
        </Col>
      </Flex>

      <Flex
        style={{
          overflow: "scroll",
          marginLeft: "5px",
        }}
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            ...pagination,
            onChange: handleTableChange,
            showSizeChanger: true,
            pageSizeOptions: [10, 15, 20, 25, 50, 100, 150, 200, 250, 500],
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </Flex>

      {open && <StudentDrawer id={activeRowId} open={open} setOpen={setOpen} />}

      <Drawer
        open={filterDrawerOpen}
        title="Filter"
        width={600}
        placement="right"
        closable
        onClose={() => setFilterDrawerOpen(false)}
      >
        <Form
          layout="vertical"
          form={filterForm}
          style={{ padding: "0px" }}
          onFinish={(e) => {
            const dateFilter =
              dateRangeType === "absolute"
                ? {
                    fromDate: dateRange[0]?.format("YYYY-MM-DD"),
                    toDate: dateRange[1]?.format("YYYY-MM-DD"),
                  }
                : dateRangeMap[presetRange];

            fetchFilteredData({
              ...e,
              ...dateFilter,
              searchFieldType,
              searchText:
                searchFieldType === "address" ? geoSearchData : searchText,
            });
            setFilterDrawerOpen(false);
          }}
        >
          <Form.Item label="Gender" name="gender">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Gender"
            >
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Status" name="status">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select Status"
            >
              <Option value="pending">Pending</Option>
              <Option value="demo">Demo</Option>
              <Option value="assigned">Assigned</Option>
              <Option value="confirmed">Confirmed</Option>
              <Option value="new">New</Option>
              <Option value="in_progress">In Progress</Option>
              <Option value="escalate">Escalate</Option>
              <Option value="inactive">Inactive</Option>
              <Option value="prospect">Prospect</Option>
              <Option value="Not_interested">Not Interested</Option>
            </Select>
          </Form.Item>

          <Form.Item label="City" name="city">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select City"
            >
              {role != "Superadmin"
                ? studentCityAccess?.map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))
                : Object.keys(citys).map((city_id) => (
                    <Option key={city_id} value={city_id}>
                      {citys[city_id]}
                    </Option>
                  ))}
            </Select>
          </Form.Item>

          <Space style={{ margin: "10px" }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "5px" }}
            >
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                filterForm.resetFields();
                setFilterDrawerOpen(false);
              }}
            >
              Cancel
            </Button>
          </Space>
        </Form>
      </Drawer>
    </>
  );
};

export default Student;
