import {atom} from "jotai";
export const studentList=atom([
    // {
    //     id:1,
    //     first_name:"suresh",
    //     last_name:"kumar",
    //     phone:"9876543210",
    //     city_id:1,
    //     status:"active",
    //     gender:"male",
    //     address:"kathmandu nepal kathmandu lorem kasdf aslkdjf s afk dlakdfj s",
    //     requirements:"maths physics chemistry",
    // },
    // {
    //     id:2,
    //     first_name:"suresh",
    //     last_name:"kumar",
    //     phone:"9876543210",
    //     city_id:1,
    //     status:"active",
    //     gender:"male",
    //     address:"kathmandu nepal kathmandu lorem kasdf aslkdjf s afk dlakdfj s",
    //     requirements:"maths physics chemistry",
    // }
])