export const academicLevels = [
  "Primary / Pre-Primary",
  "LKG / UKG",
  "1st Class",
  "2nd Class",
  "3rd Class",
  "4th Class",
  "5th Class",
  "6th Class",
  "7th Class",
  "8th Class",
  "9th Class",
  "10th Class",
  "11th Class",
  "12th Class",
];
export const courseCategories ={
//   "school education": "1",
//   Curricular: "2",
//   "Competitive Exams": "3",
//   "Intermediate Courses": "4",
//   "Abroad Exams": "5",
//   "Learn Language": "6",
//   "IT Courses": "7",
//   "Special Kids (ADHD /ADD /AUTISM)": "13",
  1: "school education",
  2: "Curricular",
  3: "Competitive Exams",
//   4: "Intermediate Courses",
  // 5: "Abroad Exams",
  6: "Learn Language",
  7: "IT Courses",
  13: "Special Kids (ADHD /ADD /AUTISM)",
};

export const teachingTypes = {
  "Looking for Part Time Tuitions": "Tutor",
  "Looking for Full time Teaching Job": "Teacher",
  Both: "Tutor,Teacher",
}