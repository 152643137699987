import React, { useState } from 'react';
import { Tabs, List } from 'antd';

const { TabPane } = Tabs;

const ActivityTab = ({ openActivities, resolvedActivities }) => {
  const [activeTab, setActiveTab] = useState('open');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Open" key="open">
        <List
          dataSource={openActivities}
          renderItem={(item) => (
            <List.Item key={item.id}>{item.description}</List.Item>
          )}
        />
      </TabPane>
      <TabPane tab="Resolved" key="resolved">
        <List
          dataSource={resolvedActivities}
          renderItem={(item) => (
            <List.Item key={item.id}>{item.description}</List.Item>
          )}
        />
      </TabPane>
    </Tabs>
  );
};

export default ActivityTab;
