import React, { useEffect, useState } from "react";
import "./TutorDrawer.css";
import imageCompression from "browser-image-compression";
import {
  Button,
  Col,
  DatePicker,
  Checkbox,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Image,
  Tag,
  Rate,
  message,
  Flex,
  notification,
  Alert,
} from "antd";
import { useAtom } from "jotai";
import { citysList } from "../states/citys";
import { tutorsList } from "../states/tutors";
import { userRole } from "../states/userRole";
import MyCourses from "./mycourses";
import axios from "../axios";
import Autocomplete from "react-google-autocomplete";
const { Option } = Select;
const teachingTypes = {
  "Looking for Part Time Tuitions": ["Tutor"],
  "Looking for Full time Teaching Job": ["Teacher"],
  Both: ["Tutor", "Teacher"],
};
const profileStatusTagColorMapper = {
  Active: "green",
  InActive: "cyan",
  Block: "red",
};
const TutorDrawer = ({ id, open, setOpen }) => {
  const [citys, setCitys] = useAtom(citysList);
  const [tutors, setTutors] = useAtom(tutorsList);
  const [data, setData] = useState(
    tutors.find((tutorRecord) => tutorRecord.id == id)
  );
  const [address, setAddress] = useState({});
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [tutorDocs, setTutorDocs] = useState({});
  const [activeEdit, setActiveEdit] = useState("");
  const [activeTeachingType, setActiveTeachingType] = useState([]);
  const [profilePicLoad, setProfilePicLoad] = useState(false);
  const [rating, setRating] = useState(null);
  const [fullCourseTemplate, setFullCourseTemplate] = useState([]);
  const [role, setRole] = useAtom(userRole);
  const [paymentDetails, setPaymentDetails] = useState({});

  const onClose = () => {
    setOpen(false);
  };

  const [step, setStep] = useState(3);

  const fetchTutorDocs = async () => {
    axios
      .get(`/v1/cre/tutor/docs?id=${id}`)
      .then((response) => {
        setTutorDocs(response.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const fetchRatingDetails = () => {
    axios
      .get(`/v1/cre/tutor/rating/${id}`)
      .then((response) => {
        const { is_success, data, error } = response.data;
        // console.log(is_success, data, error);
        if (!is_success) return;
        setRating(data);
        setActiveEdit("");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const modifyTutorsListWithUpdatedUserData = () => {
    try {
      axios
        .get(`/v1/cre/tutor?tutorId=${id}`)
        .then((res) => {
          setTutors((pre) => {
            let updatedResult = pre.map((tutorRecord) => {
              if (tutorRecord.id === id) return { ...res.data.data.Result[0] };
              return tutorRecord;
            });
            return updatedResult;
          });
          setOpen(true);
        })
        .catch((err) => {});
    } catch (error) {}
  };

  const fetchAndSetAllCourses = () => {
    try {
      axios
        .get(`/v1/cre/tutor/courses?id=${data.user_id}`)
        .then((response) => {
          setFullCourseTemplate(response.data.data);
          modifyTutorsListWithUpdatedUserData();
          setStep(3);
        })
        .catch((err) => {});
    } catch (error) {
      // console.log(error);
    }
  };

  const modifyTutorsListWithUpdatedOtherDetails = (e) => {
    let {
      teching_type,
      cre_comment,
      gender,
      experience,
      have_vehicle,
      is_approved,
      tutor_status,
      profile_status,
      tutor_badge,
      no_tution_a_month,
    } = e;

    let shouldUpdate = {
      specialTeacher: teching_type.includes("Special Educator"),
      shadowTeacher: teching_type.includes("Shadow Teacher"),
    };

    if (teching_type.includes("Tutor") && teching_type.includes("Teacher")) {
      teching_type = "Both";
    } else if (teching_type.includes("Tutor")) {
      teching_type = "Looking for Part Time Tuitions";
    } else if (teching_type.includes("Teacher")) {
      teching_type = "Looking for Full time Teaching Job";
    } else {
      teching_type = "Looking for Part Time Tuitions";
    }

    axios
      .post("/v1/cre/tutor/other-details", {
        cre_comment,
        gender,
        experience,
        have_vehicle,
        is_approved,
        teching_type,
        tutor_status,
        profile_status,
        tutor_badge,
        no_tution_a_month,
        id: data?.id,
        user_id: data?.user_id,
      })
      .then((res) => {
        // console.log(res.data);
        modifyTutorsListWithUpdatedUserData();
      })
      .catch((err) => {});

    saveTutorCourses(shouldUpdate, true);
    setActiveEdit("");
  };

  const saveTutorCourses = (
    shouldUpdate = {
      specialTeacher: false,
      shadowTeacher: false,
    },
    fromCheckbox = false
  ) => {
    let { specialTeacher, shadowTeacher } = shouldUpdate;
    let updatedCoursesData = [];
    if (fromCheckbox) {
      fullCourseTemplate.forEach((dropdownForm) => {
        if (
          !specialTeacher &&
          !shadowTeacher &&
          dropdownForm.category_id === 13
        )
          return;
        if (dropdownForm.category_id === 13) {
          let selected_courses = [];
          if (specialTeacher)
            selected_courses = [
              "Special educators at home",
              "Online (Special educator)",
            ];
          if (shadowTeacher)
            selected_courses = [
              ...selected_courses,
              "School shadow teacher",
              "Tuition support",
            ];

          updatedCoursesData.push({
            ...dropdownForm,
            selected_courses,
          });
          return;
        }

        updatedCoursesData.push(dropdownForm);
      });

      updatedCoursesData = updatedCoursesData.filter(
        (dropdownForm) => dropdownForm.selected_courses.length !== 0
      );
    } else {
      updatedCoursesData = fullCourseTemplate.filter(
        (dropdownForm) => dropdownForm.selected_courses.length !== 0
      );
    }
    axios
      .post(`/v1/cre/tutor/courses`, {
        userId: data.user_id,
        updatedCoursesData,
      })
      .then((response) => {
        fetchAndSetAllCourses();
      })
      .catch((err) => {
        // console.log(err);
        // console.log("failed to update courses ");
      });
  };

  useEffect(() => {
    setData(tutors.find((tutorRecord) => tutorRecord.id === id));
  }, [tutors]);

  useEffect(() => {
    setActiveTeachingType((pre) => {
      const courses = [
        ...new Set(data?.courses?.map((course) => course.course_name)),
      ].join(" | ");
      let teching_type = [];
      if (data?.teching_type)
        teching_type = [...teching_type, ...teachingTypes[data?.teching_type]];

      if (courses.includes("Special")) teching_type.push("Special Educator");
      if (
        courses.includes("shadow teacher") ||
        courses.includes("Tuition support")
      )
        teching_type.push("Shadow Teacher");
      return teching_type;
    });
  }, [data]);

  useEffect(() => {
    setPaymentDetails({});
    fetchAndSetAllCourses();
    fetchTutorDocs();
    fetchRatingDetails();
    fetchPaymentDetails();
  }, []);

  const updatePersonalDetails = (data) => {
    axios
      .post("/v1/cre/tutor/personal-details", { ...data })
      .then((res) => {
        modifyTutorsListWithUpdatedUserData();
        setActiveEdit("");
      })
      .catch((err) => {
      });
  };

   const sendPaymentRecipt = async (paymentId) => {
    try {
      axios
        .post(`/v1/cre/tutor/payment-reciept`, {
          paymentId,
        })
        .then((response) => {
          const { is_success } = response.data;
          if (is_success) {
            notification.success({
              message: "Send Reciept",
              description: "Reciept sent to your email",
              duration: 2,
            });
          }
        });
    } catch (error) {}
  };

  const emailInitialSubcriptionPayment = async () => {
    try {
      const user_id = data?.user_id;
      axios
        .get(`/v1/cre/tutor/payment-invoice/${user_id}`, {
          params: {
          },
        })
        .then((response) => {
          notification.success({
            message: "Send Payment Link",
            description: "Payment link sent to " + data?.email + " email "+user_id,
            duration: 2,
          });
          modifyTutorsListWithUpdatedUserData();
          fetchPaymentDetails();
        })
        .catch((error) => {});
    } catch (error) {}
  };
  const fetchPaymentDetails = async () => {
    try {
      const user_id = data?.user_id;
      axios
        .get(`/v1/cre/user/payment-details/${user_id}`)
        .then((response) => {
          const { data, is_success } = response.data;
          console.log(data, is_success);
          if (is_success) {
            setPaymentDetails(data);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const onInputClick = (event) => {
    event.target.value = null;
  };

  const ShowDoc = ({ src }) => {
    if (!src) return <>Not Uploaded</>;
    const img = ".jpg,.jpeg,.png,.gif,.mp4,.mov,.avi".includes(
      src.split(".").pop().toLowerCase()
    );

    return img ? (
      <Image width={150} src={src} />
    ) : (
      <>
        <a
          href={src}
          target="_blank"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          rel="noopener noreferrer"
        >
          show Doc
        </a>
      </>
    );
  };

  const ShowUploadOption = ({ id }) => {
    return (
      <input
        id={id}
        type="file"
        name="myImage"
        onClick={onInputClick}
        accept=".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif"
        onChange={(e) => uploadDoc(e, id)}
        style={{ display: "none" }}
      />
    );
  };

  const uploadedProfilePic = (e) => {
    try {
      let uploadedFile = e.target.files[0];
      if (uploadedFile && uploadedFile.size <= 5 * 1024 * 1024) {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 1920,
        };

        imageCompression(e.target.files[0], options)
          .then((response) => {
            uploadedFile = new File([response], e.target.files[0].name, {
              lastModified: e.target.files[0].lastModified,
              type: e.target.files[0].type,
            });

            setProfilePicLoad(true);
            const formData = new FormData();

            formData.append("myImage", uploadedFile);
            return axios.post(
              `v1/cre/tutor/profile-pic?userId=${data?.user_id}`,
              formData,
              {
                headers: { "content-type": "multipart/form-data" },
              }
            );
          })
          .then((response) => {
            setProfilePicLoad(false);
            modifyTutorsListWithUpdatedUserData();
          })
          .catch((error) => {
            setProfilePicLoad(false);
            alert("Error While Uploading Profile Pic");
          });
      } else {
        alert("Image size should be less than or equal to 5MB");
      }
    } catch (error) {
      alert("Error While Uploading Profile Pic");
    }
  };

  const uploadDoc = (e, docType) => {
    try {
      const uploadedFile = e.target.files[0];
      if (
        !".pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif".includes(
          uploadedFile.name.split(".").slice(-1)[0]
        )
      ) {
        alert(
          "only .pdf, .doc, .docx, .txt, .ppt, .jpg, .jpeg, .png, .gif format files are allowed"
        );
        return;
      }
      if (uploadedFile && uploadedFile.size <= 10 * 1024 * 1024) {
        const formData = new FormData();
        formData.append("myImage", uploadedFile);
        axios
          .post(`v1/cre/tutor/doc?userId=${data?.user_id}`, formData, {
            headers: {
              "content-type": "multipart/form-data",
              "doc-type": docType,
            },
          })
          .then((res) => {
            fetchTutorDocs();
          })
          .catch((error) => {
            alert("Error While Uploading Document");
          });
      } else {
        alert("Document size should be less than or equal to 10 MB");
      }
    } catch (error) {
      alert("Error While Uploading Document");
    }
  };

  return (
    <>
      <Drawer
        title={`Account Details of ${data?.id} - ${data?.user_id}`}
        width={"90vw"}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button
              onClick={() => {
                setOpen(false);

                modifyTutorsListWithUpdatedUserData();
              }}
              type="primary"
            >
              Refresh
            </Button>
          </Space>
        }
      >
        <section className="edit-pannel-profile-section-wrapper">
          <div className="approved-icon">
            <Image
              height={100}
              src={data?.profile_pic_link}
              fallback="./person-icon.png"
            />
            {data?.is_approved ? (
              <div
                style={{
                  backgroundColor: "rgba(44, 153, 27, 1)",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "7px",
                  position: "absolute",
                  right: "3px",
                  bottom: "25px",
                }}
              >
                <img src="./approved-icon.svg" alt="tick mark" />
              </div>
            ) : (
              <></>
            )}
            <div className="showRequest--Edit-btn">
              {/* {console.log(data?.profile_pic_link)} */}
              {!profilePicLoad ? (
                <form>
                  <label htmlFor="profilePic-1">File Upload</label>
                  <input
                    id="profilePic-1"
                    type="file"
                    name="myImage"
                    accept=".jpg, .jpeg, .png, .gif"
                    onChange={uploadedProfilePic}
                    onClick={onInputClick}
                    style={{ display: "none" }}
                  />
                </form>
              ) : (
                <form>
                  <label>Uploading</label>
                </form>
              )}
            </div>
          </div>

          <div className="name-place-status-wrapper">
            <div className="fullname-title">
              {data?.first_name} {data?.last_name}
            </div>
            <div className="place-gender-title">
              {citys[data?.city_id]}
              {" - "}
              {data?.gender}
            </div>
            <div className="active-status-title">
              {rating && (
                <Rate
                  allowHalf
                  defaultValue={
                    Object.values(rating).reduce(
                      (acc, cur) => acc + parseFloat(cur),
                      0
                    ) / Object.keys(rating).length
                  }
                  disabled={true}
                />
              )}
              <Tag
                className="active-status-value"
                color={
                  profileStatusTagColorMapper[
                    data?.profile_status ? data?.profile_status : "Active"
                  ]
                }
              >
                {data?.profile_status ? data?.profile_status : "Active"}
              </Tag>
            </div>
          </div>
        </section>

        {/* ---------------------------------------- personal details start -------------------------------- */}

        <section className="section-title-edit-wrapper">
          <div className="section-title-container">
            Personal Details
            <div
              style={{
                backgroundColor: "rgba(44, 153, 27, 1)",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "7px",
              }}
            >
              <img src="./white-tick.svg" alt="tick mark" />
            </div>
          </div>
          {activeEdit === "" && (
            <div
              onClick={() => {
                setActiveEdit("personal-details");
              }}
              className="section-edit-container"
            >
              Edit
            </div>
          )}
        </section>
        {activeEdit !== "personal-details" && (
          <section>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">First Name : </span>
              <span className="profile-section-value">{data?.first_name}</span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Last Name : </span>
              <span className="profile-section-value">{data?.last_name}</span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Phone : </span>
              <span className="profile-section-value">{data?.phone}</span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Alt Phone : </span>
              <span className="profile-section-value">{data?.phone2}</span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Email : </span>
              <span className="profile-section-value">{data?.email}</span>
            </div>

            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Address: </span>
              <span className="profile-section-value">
                {data?.address
                  ?.split("<-->")
                  .map((area) => {
                    if (area != "undefined") return area;
                    return "";
                  })
                  .join(" ")}
              </span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">City : </span>
              <span className="profile-section-value">
                {citys[data?.city_id]}
              </span>
            </div>
          </section>
        )}
        {activeEdit === "personal-details" && (
          <Form
            layout="vertical"
            onFinish={(e) => {
              const oldAddress = data.address.split("<-->");
              const updatedData = {
                userId: data.user_id,
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone,
                phone2: data.phone2,
                email: data.email,
                flat: oldAddress[0],
                apartment: oldAddress[1],
                street: oldAddress[2],
                city_name: data.city_name,
                locality: oldAddress[3],
                zip: data.zip,
                latitude: data.latitude,
                longitude: data.longitude,
                user_type: "tutor",
                ...e,
                ...address,
              };
              updatePersonalDetails(updatedData);
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="first_name"
                  label="First Name"
                  className="profile-section-title"
                  initialValue={data?.first_name}
                  rules={[
                    {
                      required: true,
                      message: "Please enter user first name",
                    },
                  ]}
                >
                  <Input placeholder="Please enter user first name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="last_name"
                  label="Last Name"
                  className="profile-section-title"
                  initialValue={data?.last_name}
                  rules={[
                    {
                      required: true,
                      message: "Please enter user last name",
                    },
                  ]}
                >
                  <Input placeholder="Please enter user last name" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  name="phone"
                  label="Phone"
                  initialValue={data?.phone}
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user phone no",
                    },
                  ]}
                >
                  <Input placeholder="Please enter user phone no" />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  name="phone2"
                  label="Alter Phone"
                  className="profile-section-title"
                  initialValue={data?.phone2}
                  rules={[
                    {
                      // required: true,
                      message: "Please enter user alter phone no",
                    },
                  ]}
                >
                  <Input placeholder="Please enter user alter phone no" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user email",
                    },
                  ]}
                  initialValue={data?.email}
                >
                  <Input placeholder="Please enter user email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="flat"
                  label="Flat No"
                  className="profile-section-title"
                  rules={[
                    {
                      required: false,
                      message: "Please enter user flat no",
                    },
                  ]}
                  initialValue={data?.address.split("<-->")[0]}
                >
                  <Input placeholder="Please enter user flat no" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="apartment"
                  label="Apartment Name"
                  className="profile-section-title"
                  rules={[
                    {
                      required: false,
                      message: "Please enter user apartment name ",
                    },
                  ]}
                  initialValue={data?.address.split("<-->")[1]}
                >
                  <Input placeholder="Please enter user apartment name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="street"
                  label="Street Name"
                  className="profile-section-title"
                  rules={[
                    {
                      required: false,
                      message: "Please enter user street name",
                    },
                  ]}
                  initialValue={data?.address.split("<-->")[2]}
                >
                  <Input placeholder="Please enter user street name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city_name"
                  label="City"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user city",
                    },
                  ]}
                  initialValue={data?.city_name}
                >
                  <Select placeholder="Please enter user city" showSearch>
                    {Object.keys(citys).map((city_id) => (
                      <Option key={city_id} value={citys[city_id]}>
                        {citys[city_id]}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="full_address"
                  label="Pincode"
                  className="profile-section-title"
                  rules={[
                    {
                      required: false,
                      message: "Please enter user pincode",
                    },
                  ]}
                  // initialValue={data?.zip}
                >
                  {/* <Input placeholder="Please enter user flat no" /> */}
                  <Autocomplete
                    apiKey={apiKey}
                    style={{ width: "95%" }}
                    className="ant-input css-dev-only-do-not-override-1uweeqc ant-input-outlined"
                    onPlaceSelected={(place) => {
                      let addressComponents = place.address_components;
                      let pinCode = addressComponents.find((component) =>
                        component.types.includes("postal_code")
                      );
                      let locality = addressComponents
                        .map((geo) => geo.long_name)
                        .join(", ");
                      let city = addressComponents.find(
                        (component) =>
                          component.types.includes("locality") &&
                          component.types.includes("political")
                      );
                      let state = addressComponents.find(
                        (component) =>
                          component.types.includes(
                            "administrative_area_level_1"
                          ) && component.types.includes("political")
                      );
                      let latitude = place.geometry.location.lat();
                      let longitude = place.geometry.location.lng();
                      // console.log({
                      //   pincode: pinCode ? pinCode.short_name : " ",
                      //   city: city ? city.long_name : " ",
                      //   state: state ? state.long_name : " ",
                      //   locality: locality,
                      //   latitude: latitude,
                      //   longitude: longitude,
                      //   gmap_meta_data: JSON.stringify(addressComponents),
                      // });
                      setAddress({
                        zip: pinCode ? pinCode.short_name : " ",
                        // city_name: city ? city.long_name : " ",
                        // state: state ? state.long_name : " ",
                        locality: locality,
                        latitude: latitude,
                        longitude: longitude,
                        gmap_meta_data: JSON.stringify(addressComponents),
                      });
                      // setAddress((prevAddress) => ({
                      //   ...prevAddress,
                      //   pincode: pinCode ? pinCode.short_name : " ",
                      //   city: city ? city.long_name : " ",
                      //   state: state ? state.long_name : " ",
                      //   locality: locality,
                      //   latitude: latitude,
                      //   longitude: longitude,
                      //   gmap_meta_data: JSON.stringify(addressComponents),
                      // }));
                    }}
                    options={{
                      types: [
                        // "hospital",
                        // "pharmacy",
                        // "bakery",
                        // "sublocality",
                        // ,
                        // "landmark",
                        // "locality",
                        // "postal_code",
                        // "postal_code_prefix",
                        // "postal_code_suffix",
                        // "postal_town",
                        // "street_address",
                        // "street_number",
                        // "sublocality",
                        // "sublocality_level_1",
                        // "sublocality_level_2",
                        // "sublocality_level_3",
                        // "sublocality_level_4",
                        // "sublocality_level_5",
                        // "subpremise",
                        // "town_square",
                      ],

                      componentRestrictions: { country: "IN" }, // Set country to "IN" for India
                    }}
                    defaultValue={data?.zip}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  htmlType="button"
                  onClick={() => {
                    setActiveEdit("");
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}

        {/* ---------------------------------------- personal details end -------------------------------- */}

        {/* ---------------------------------------- Rating details start-------------------------------- */}

        <section className="section-title-edit-wrapper">
          <div className="section-title-container">
            Rating Details
            <div
              style={{
                backgroundColor: "rgba(44, 153, 27, 1)",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "7px",
              }}
            >
              <img src="./white-tick.svg" alt="tick mark" />
            </div>
          </div>
          {activeEdit === "" && (
            <div
              onClick={() => {
                setActiveEdit("rating-details");
              }}
              className="section-edit-container"
            >
              Edit
            </div>
          )}
        </section>

        {activeEdit !== "rating-details" && rating && (
          <Flex wrap="wrap" className="rating-details-section">
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">
                personality Rating :{" "}
              </span>
              <span className="profile-section-title">
                <Rate
                  allowHalf
                  defaultValue={parseFloat(rating?.personal)}
                  disabled={true}
                />
              </span>
            </div>

            {/* <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">behaviour Rating : </span>
              <span className="profile-section-title">
                <Rate
                  allowHalf
                  defaultValue={parseFloat(rating?.behaviour)}
                  disabled={true}
                />
              </span>
            </div> */}

            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">subject Rating : </span>
              <span className="profile-section-title">
                <Rate
                  allowHalf
                  defaultValue={parseFloat(rating?.subject)}
                  disabled={true}
                />
              </span>
            </div>

            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">
                communication Rating :{" "}
              </span>
              <span className="profile-section-title">
                <Rate
                  allowHalf
                  defaultValue={parseFloat(rating?.communication)}
                  disabled={true}
                />
              </span>
            </div>

            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">trustable Rating : </span>
              <span className="profile-section-title">
                <Rate
                  allowHalf
                  defaultValue={parseFloat(rating?.trustable)}
                  disabled={true}
                />
              </span>
            </div>
          </Flex>
        )}

        {activeEdit === "rating-details" && (
          <Form
            requiredMark={false}
            name="basic"
            initialValues={{
              rating: data?.rating,
            }}
            onFinish={(values) => {
              axios
                .put(`/v1/cre/tutor/rating/${id}`, values)
                .then((res) => {
                  const { is_success, data, error } = res.data;
                  // console.log(res.data);
                  if (is_success) {
                    message.success("Rating updated successfully");
                    setActiveEdit("");
                    setRating(null);
                    fetchRatingDetails();
                  } else {
                    // console.log(error);
                  }
                })
                .catch((err) => {
                  // console.log(err);
                });
              setActiveEdit("");
            }}
          >
            <Row gutter={16}>
              <Col span={25}>
                <Form.Item
                  name="personal"
                  label="Personality Rating"
                  rules={[
                    {
                      required: true,
                      message: "Please enter personal rating",
                    },
                  ]}
                  initialValue={parseFloat(rating.personal)}
                >
                  <Rate allowHalf defaultValue={rating.personal} />
                </Form.Item>
              </Col>

              {/* <Col span={25}>
                <Form.Item
                  name="behaviour"
                  label="Behaviour Rating"
                  rules={[
                    {
                      required: true,
                      message: "Please enter behaviour rating",
                    },
                  ]}
                  initialValue={parseFloat(rating.behaviour)}
                >
                  <Rate allowHalf defaultValue={rating.behaviour} />
                </Form.Item>
              </Col> */}

              <Col span={25}>
                <Form.Item
                  name="subject"
                  label="Subject Rating"
                  rules={[
                    {
                      required: true,
                      message: "Please enter subject rating",
                    },
                  ]}
                  initialValue={parseFloat(rating.subject)}
                >
                  <Rate allowHalf defaultValue={rating.subject} />
                </Form.Item>
              </Col>

              <Col span={25}>
                <Form.Item
                  name="communication"
                  label="Communication Rating"
                  rules={[
                    {
                      required: true,
                      message: "Please enter communication rating",
                    },
                  ]}
                  initialValue={parseFloat(rating.communication)}
                >
                  <Rate allowHalf defaultValue={rating.communication} />
                </Form.Item>
              </Col>

              <Col span={25}>
                <Form.Item
                  name="trustable"
                  label="Trustable Rating"
                  rules={[
                    {
                      required: true,
                      message: "Please enter trustable rating",
                    },
                  ]}
                  initialValue={parseFloat(rating.trustable)}
                >
                  <Rate allowHalf defaultValue={rating.trustable} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  htmlType="button"
                  onClick={() => {
                    setActiveEdit("");
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}

        {/* ---------------------------------------- Rating details end-------------------------------- */}

        {/* ---------------------------------------- other details start-------------------------------- */}

        <section className="section-title-edit-wrapper">
          <div className="section-title-container">
            Other Details
            <div
              style={{
                backgroundColor: "rgba(44, 153, 27, 1)",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "7px",
              }}
            >
              <img src="./white-tick.svg" alt="tick mark" />
            </div>
          </div>
          {activeEdit === "" && (
            <div
              onClick={() => {
                setActiveEdit("other-details");
              }}
              className="section-edit-container"
            >
              Edit
            </div>
          )}
        </section>

        {activeEdit !== "other-details" && (
          <section>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Current Status : </span>
              <span className="profile-section-value">
                {data?.tutor_status}
              </span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Active Status : </span>
              <span className="profile-section-value">
                {data?.profile_status}
              </span>
            </div>
            {/* <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Rating : </span>
              <span className="profile-section-value">
                <Rate
                  allowHalf
                  defaultValue={parseFloat(data?.no_tution_a_month)}
                  disabled={true}
                />
              </span>
            </div> */}
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Experience : </span>
              <span className="profile-section-value">{data?.experience}</span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Gender : </span>
              <span className="profile-section-value">{data?.gender}</span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Teaching Type : </span>
              <span className="profile-section-value">
                {activeTeachingType.join(" | ")}
              </span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Have Vehicle : </span>
              <span className="profile-section-value">
                {data?.have_vehicle === 1 ? "Yes " : "No"}
              </span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Verify Status : </span>
              <span className="profile-section-value">
                {data?.is_approved === 1 ? "Verified" : "Not Verified"}
              </span>
            </div>
            <Alert
              style={{ margin: "20px 0" }}
              message={
                paymentDetails?.subscriptionData?.status_code === "SUCCESS"
                  ? "Subscription Status: Success"
                  : `Subscription Status: Pending - Email sent count: ${
                      paymentDetails?.subscriptionData?.invoice_sent_count || 0
                    }`
              }
              type={
                paymentDetails?.subscriptionData?.status_code === "SUCCESS"
                  ? "success"
                  : "error"
              }
              showIcon
              action={
                paymentDetails?.subscriptionData?.status_code === "SUCCESS" ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      sendPaymentRecipt(
                        paymentDetails?.subscriptionData?.payment_id
                      );
                    }}
                  >
                    Send Reciept
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => {
                      // send email for payment
                      emailInitialSubcriptionPayment();
                    }}
                  >
                    Email Payment Link
                  </Button>
                )
              }
            />
            {/* <div
              className="profile-section-personal-details-titles-wrapper"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <span className="profile-section-title">Payment Status : </span>
                <span className="profile-section-value">
                  {data?.is_paid === 1 ? "Paid" : "Not Paid"}
                </span>
              </div>
              {data?.is_paid !== 1 && (
                <div
                  onClick={() => {
                    payNow();
                  }}
                  className="profile-section-value get-payment-link-btn"
                >
                  Email Payment Request
                </div>
              )}
            </div> */}
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Comment : </span>{" "}
              <span className="profile-section-value">{data?.cre_comment}</span>
            </div>
          </section>
        )}

        {activeEdit === "other-details" && (
          <Form
            layout="vertical"
            // hideRequiredMark
            onFinish={(e) => {
              // console.log(e);
              modifyTutorsListWithUpdatedOtherDetails(e);
            }}
          >
            {/* <Row gutter={16}>
              <Col span={25}>
                <Form.Item
                  name="no_tution_a_month"
                  label="Tutor Rating"
                  className="profile-section-title"
                  rules={[
                    {
                      required: false,
                      message: "Please give tutor rating",
                    },
                  ]}
                  initialValue={parseFloat(data?.no_tution_a_month)}
                >
                  <Rate
                    allowHalf
                    defaultValue={parseFloat(data?.no_tution_a_month)}
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={16}>
              <Col span={25}>
                <Form.Item
                  name="teching_type"
                  label="Teaching Type"
                  className="profile-section-title"
                  rules={[
                    {
                      required: false,
                      message: "Please select teaching type",
                    },
                  ]}
                  initialValue={activeTeachingType}
                >
                  <Checkbox.Group defaultValue={activeTeachingType}>
                    <Space direction="">
                      <Checkbox value="Tutor">Tutor</Checkbox>
                      <Checkbox value="Teacher">Teacher</Checkbox>
                      <Checkbox value="Special Educator">
                        Special Educator
                      </Checkbox>
                      <Checkbox value="Shadow Teacher">Shadow Teacher</Checkbox>
                    </Space>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="tutor_status"
                  label="Current Status"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please choose Current status ",
                    },
                  ]}
                  initialValue={data?.tutor_status}
                >
                  <Select
                    placeholder="Please choose Current status"
                    initialValue={data?.tutor_status}
                  >
                    <Option value={"New"}>New</Option>
                    <Option value={"Interested"}>Interested</Option>
                    <Option value={"Doc_Done"}>Doc Done</Option>
                    <Option value={"Not_Interested"}>Not Interested</Option>
                    <Option value={"Payment_Done"}>Payment Done</Option>
                    {role !== "Superviser" && (
                      <Option value={"Verified"}>Verified</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="profile_status"
                  label="Active Status"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please choose active status ",
                    },
                  ]}
                  initialValue={data?.profile_status}
                >
                  <Select
                    placeholder="Please choose active status"
                    initialValue={data?.profile_status}
                  >
                    <Option value={"Active"}>Active</Option>
                    <Option value={"InActive"}>In Active</Option>
                    <Option value={"Block"}>Block</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="experience"
                  label="Experience"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please select Experience",
                    },
                  ]}
                  initialValue={data?.experience}
                >
                  <Select
                    initialValue={data?.experience}
                    placeholder="Please select Experience"
                  >
                    <Option value="Fresher">Fresher</Option>
                    <Option value="1 year">1 year</Option>
                    <Option value="2 years">2 years</Option>
                    <Option value="3 years">3 years</Option>
                    <Option value="4 years">4 years</Option>
                    <Option value="5 years">5 years</Option>
                    <Option value="6 years">6 years</Option>
                    <Option value="7 years">7 years</Option>
                    <Option value="8 years">8 years</Option>
                    <Option value="9 years">9 years</Option>
                    <Option value="10 years">10 years</Option>
                    <Option value="11 years">11 years</Option>
                    <Option value="12 years">12 years</Option>
                    <Option value="13 years">13 years</Option>
                    <Option value="14 years">14 years</Option>
                    <Option value="15 years">15 years</Option>
                    <Option value="16 years">16 years</Option>
                    <Option value="17 years">17 years</Option>
                    <Option value="18 years">18 years</Option>
                    <Option value="19 years">19 years</Option>
                    <Option value="20 years">20 years</Option>
                    <Option value="21 years">21 years</Option>
                    <Option value="22 years">22 years</Option>
                    <Option value="23 years">23 years</Option>
                    <Option value="24 years">24 years</Option>
                    <Option value="25 years">25 years</Option>
                    <Option value="26 years">26 years</Option>
                    <Option value="27 years">27 years</Option>
                    <Option value="28 years">28 years</Option>
                    <Option value="29 years">29 years</Option>
                    <Option value="30 years">30 years</Option>
                    <Option value=">30 years">Above 30 years</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please select an gender",
                    },
                  ]}
                  initialValue={data?.gender}
                >
                  <Select
                    placeholder="Please select an gender"
                    initialValue={data?.gender}
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col span={12}>
                <Form.Item
                  name="teching_type"
                  label="Teaching Type"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please select an teaching type",
                    },
                  ]}
                  initialValue={data?.teching_type}
                >
                  <Select
                    placeholder="Please select an teaching type"
                    initialValue={data?.teching_type}
                  >
                    <Option value="Looking for Part Time Tuitions">
                      Looking for Part Time Tuitions
                    </Option>
                    <Option value="Looking for Full Time Tuitions">
                      Looking for Full Time Tuitions
                    </Option>
                    <Option value="Both">Both</Option>
                  </Select>
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  name="have_vehicle"
                  label="Have Vehicle"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please choose have vehicle ",
                    },
                  ]}
                  initialValue={data?.have_vehicle}
                >
                  <Select
                    placeholder="Please choose have vehicle"
                    initialValue={data?.have_vehicle}
                  >
                    <Option value={0}>No</Option>
                    <Option value={1}>Yes</Option>
                  </Select>
                </Form.Item>
              </Col>
              {(role === "Admin" || role === "Superadmin") && (
                <Col span={12}>
                  <Form.Item
                    name="is_approved"
                    label="Verify Status"
                    className="profile-section-title"
                    rules={[
                      {
                        required: true,
                        message: "Please choose Approve Status ",
                      },
                    ]}
                    initialValue={data?.is_approved}
                  >
                    <Select
                      placeholder="Please choose Approve Status"
                      initialValue={data?.is_approved}
                    >
                      <Option value={0}>Not Verified</Option>
                      <Option value={1}>Verified</Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {/* <Col span={12}>
                <Form.Item
                  name="is_paid"
                  label="Payment Status"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please choose Payment Status ",
                    },
                  ]}
                  initialValue={data?.is_paid}
                >
                  <Select
                    placeholder="Please choose Payment Status"
                    initialValue={data?.is_paid}
                  >
                    <Option value={0}>Not Paid</Option>
                    <Option value={1}>Paid</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city_id"
                  label="City"
                  className="profile-section-title"
                  rules={[
                    {
                      required: true,
                      message: "Please choose City ",
                    },
                  ]}
                  initialValue={citys[data?.city_id]}
                >
                  <Select
                    placeholder="Please choose City"
                    initialValue={citys[data?.city_id]}
                  >
                    {Object.keys(citys).map((cityId) => {
                      return <Option value={cityId}>{citys[cityId]}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="cre_comment"
                  className="profile-section-title"
                  label="Comment"
                  rules={[
                    {
                      required: false,
                      message: "please enter comment",
                    },
                  ]}
                  initialValue={data?.cre_comment}
                >
                  <Input.TextArea
                    initialValue={data?.cre_comment}
                    rows={4}
                    placeholder="please enter comment"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  htmlType="button"
                  onClick={() => {
                    setActiveEdit("");
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </Form.Item>
          </Form>
        )}

        {/* ---------------------------------------- other details end-------------------------------- */}

        {/* ---------------------------------------- Document details start-------------------------------- */}

        <section className="section-title-edit-wrapper">
          <div className="section-title-container">
            Document Details
            <div
              style={{
                backgroundColor: "rgba(44, 153, 27, 1)",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "7px",
              }}
            >
              <img src="./white-tick.svg" alt="tick mark" />
            </div>
          </div>
          {/* {activeEdit === "" && (
            <div
              onClick={() => {
                setActiveEdit("document-details");
              }}
              className="section-edit-container"
            >
              Edit
            </div>
          )} */}
        </section>

        {activeEdit !== "document-details" && (
          <section className="documents-section">
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">
                ID Proof-1
                <br />
                <label htmlFor="adhaar" className="file-upload-btn">
                  upload
                </label>
              </span>
              <span className="profile-section-value">
                {" "}
                : <ShowDoc src={tutorDocs?.adhaar} />
                <ShowUploadOption id="adhaar" />
              </span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">
                ID Proof-2
                <br />
                <label htmlFor="id_card" className="file-upload-btn">
                  upload
                </label>
              </span>
              <span className="profile-section-value">
                : <ShowDoc src={tutorDocs?.id_card} />
                <ShowUploadOption id="id_card" />
              </span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">
                CV Doc
                <br />
                <label htmlFor="cv_file" className="file-upload-btn">
                  upload
                </label>
              </span>
              <span className="profile-section-value">
                : <ShowDoc src={tutorDocs?.cv_file} />
                <ShowUploadOption id="cv_file" />
              </span>
            </div>
            <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">
                Resume
                <br />
                <label htmlFor="resume_file" className="file-upload-btn">
                  upload
                </label>
              </span>
              <span className="profile-section-value">
                : <ShowDoc src={tutorDocs?.resume_file} />
                <ShowUploadOption id="resume_file" />
              </span>
            </div>
            {/* <div className="profile-section-personal-details-titles-wrapper">
              <span className="profile-section-title">Intro Video : </span>
              <span className="profile-section-value">
                <Image
                  width={150}
                  src={tutorDocs?.into_video}
                  fallback="./person-icon.png"
                />
              </span>
            </div> */}
          </section>
        )}

        {/* ---------------------------------------- Document details end-------------------------------- */}
        {/* ---------------------------------------- Payments details Start-------------------------------- */}

        {/* <section className="section-title-edit-wrapper">
          <div className="section-title-container">Paymnets Details</div>
          {activeEdit === "" && (
            <div
              className="section-edit-container"
            >
            </div>
          )}
        </section> */}

        {/* ---------------------------------------- Payments details End-------------------------------- */}

        {/* {JSON.stringify(data)} */}

        <section className="section-title-edit-wrapper">
          <div className="section-title-container">
            Courses Details
            <div
              style={{
                backgroundColor: "rgba(44, 153, 27, 1)",
                borderRadius: "50%",
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "7px",
              }}
            >
              <img src="./white-tick.svg" alt="tick mark" />
            </div>
          </div>
          {step === 3 && (
            <div
              className="section-edit-container"
              onClick={() => {
                setStep(0);
              }}
            >
              Edit
            </div>
          )}
          {step !== 3 && (
            <div
              className="section-edit-container"
              onClick={() => {
                fetchAndSetAllCourses();
              }}
            >
              Cancel
            </div>
          )}
        </section>

        {fullCourseTemplate.length !== 0 && (
          <MyCourses
            step={step}
            setStep={setStep}
            fullCourseTemplate={fullCourseTemplate}
            setFullCourseTemplate={setFullCourseTemplate}
            saveTutorCourses={saveTutorCourses}
          />
        )}
      </Drawer>
    </>
  );
};
export default TutorDrawer;
