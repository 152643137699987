import React, { useEffect, useState } from "react";
import "./mycourses.css";

import { Space, Button, Form, Checkbox, Row, Col, Radio } from "antd";

export default function MyCourses({
  step,
  setStep,
  fullCourseTemplate,
  setFullCourseTemplate,
  saveTutorCourses,
}) {
  const handleCourseChange = (course, dropdownFormIndx, oldDropDownForm) => {
    let updatedCourseTemplate = [...fullCourseTemplate];
    const index =
      updatedCourseTemplate[dropdownFormIndx]?.selected_courses.indexOf(course);

    if (course === "All Subjects" && index === -1) {
      updatedCourseTemplate[dropdownFormIndx].selected_courses =
        updatedCourseTemplate[dropdownFormIndx].src_courses;
    } else if (course === "All Subjects") {
      updatedCourseTemplate[dropdownFormIndx].selected_courses = [];
    } else if (index === -1) {
      updatedCourseTemplate[dropdownFormIndx].selected_courses.push(course);
    } else {
      const AllCoursesIndex =
        updatedCourseTemplate[dropdownFormIndx]?.selected_courses.indexOf(
          "All Subjects"
        );
      updatedCourseTemplate[dropdownFormIndx].selected_courses.splice(index, 1);
      if (AllCoursesIndex !== -1)
        updatedCourseTemplate[dropdownFormIndx].selected_courses.splice(
          AllCoursesIndex,
          1
        );
    }
    setFullCourseTemplate(updatedCourseTemplate);
  };

  const returnCourses = () => {
    let resultCourseFroms = [];
    fullCourseTemplate.map(
      (dropdownForm, dropdownFormIndx, oldDropDownForm) => {
        let Title = dropdownForm.title;
        if (dropdownFormIndx <= 2 && dropdownFormIndx >= 1) {
          Title = "Primary / Pre-Primary / LKG / UKG";
          dropdownForm.src_courses = ["All Subjects"];
        } else if (dropdownFormIndx >= 3 && dropdownFormIndx <= 7) {
          Title = "1st - 5th Classes";
          dropdownForm.src_courses = [
            "All Subjects",
            "All Subjects Except Language",
            ...dropdownForm.src_courses.filter(
              (course) =>
                !["All Subjects", "All Subjects Except Language"].includes(
                  course
                )
            ),
          ];
        } else if (dropdownFormIndx >= 8 && dropdownFormIndx <= 10) {
          Title = "6th - 8th Classes";
          dropdownForm.src_courses = [
            "All Subjects",
            "All Subjects Except Language",
            ...dropdownForm.src_courses.filter(
              (course) =>
                !["All Subjects", "All Subjects Except Language"].includes(
                  course
                )
            ),
          ];
        } else if (dropdownFormIndx >= 11 && dropdownFormIndx <= 12) {
          dropdownForm.src_courses = [
            ...dropdownForm.src_courses.filter(
              (course) =>
                !["All Subjects", "All Subjects Except Language"].includes(
                  course
                )
            ),
          ];
          // Title="9th - 10th Classes"
        } else if (dropdownFormIndx >= 32 && dropdownFormIndx <= 35) {
          Title = "Special Kid( ADHD/ADD/AUTISM )";
        }
        resultCourseFroms.push(
          <form
            key={dropdownFormIndx}
            className={`form--wrapper ${
              [0, 2, 4, 5, 6, 7, 9, 10, 33, 34, 35].includes(dropdownFormIndx)
                ? "none-display"
                : ""
            }`}
          >
            <div
              className={`form-heading ${
                dropdownForm.opened ||
                dropdownForm.selected_courses.length !== 0
                  ? "active-form-heading"
                  : "inactive-form-heading"
              }`}
              onClick={() => {
                let updatedCourseTemplate = [...fullCourseTemplate];
                updatedCourseTemplate = updatedCourseTemplate.map(
                  (form, indx) => {
                    if (indx !== dropdownFormIndx) form.opened = false;
                    return form;
                  }
                );
                updatedCourseTemplate[dropdownFormIndx].opened =
                  !dropdownForm.opened;
                setFullCourseTemplate(updatedCourseTemplate);
              }}
            >
              {Title}
              {!dropdownForm.opened &&
              dropdownForm.selected_courses.length === 0 ? (
                <img
                  src="./down-arrow-grey-icon.svg"
                  alt="down-arrow-grey-icon"
                />
              ) : dropdownForm.opened ? (
                <img src="./up-arrow-grey-icon.svg" alt="up-arrow-grey-icon" />
              ) : (
                <img
                  src="./down-arrow-white-icon.svg"
                  alt="down-arrow-white-icon"
                />
              )}
            </div>
            <div
              className={`${dropdownForm.opened && "form-options--wrapper"}`}
            >
              {dropdownForm.opened &&
                dropdownForm.src_courses.map((course, index) => (
                  <Row key={index} className={`form-option`}>
                    <Col span={30}>
                      <Checkbox
                        checked={dropdownForm.selected_courses.includes(course)}
                        onChange={() => {
                          const handleCourseChangeForRange = (start, end) => {
                            for (let i = start; i <= end; i++) {
                              handleCourseChange(course, i, oldDropDownForm);
                            }
                          };

                          if (dropdownFormIndx >= 1 && dropdownFormIndx <= 2) {
                            handleCourseChangeForRange(1, 2);
                          } else if (
                            dropdownFormIndx >= 3 &&
                            dropdownFormIndx <= 7
                          ) {
                            handleCourseChangeForRange(3, 7);
                          } else if (
                            dropdownFormIndx >= 8 &&
                            dropdownFormIndx <= 10
                          ) {
                            handleCourseChangeForRange(8, 10);
                          } else if (
                            dropdownFormIndx >= 32 &&
                            dropdownFormIndx <= 35
                          ) {
                            handleCourseChangeForRange(32, 35);
                          } else {
                            handleCourseChange(
                              course,
                              dropdownFormIndx,
                              oldDropDownForm
                            );
                          }
                        }}
                      >
                        {course}
                      </Checkbox>
                    </Col>
                  </Row>
                ))}
            </div>
          </form>
        );
        return dropdownForm;
      }
    );

    return (
      <div className={`all-forms-wrapper`}>
        <div className="left-forms-wrapper">
          {resultCourseFroms.slice(
            0,
            Math.ceil((fullCourseTemplate.length + 5) / 2)
          )}
        </div>
        <div className="right-forms-wrapper">
          {resultCourseFroms.slice(
            Math.ceil((fullCourseTemplate.length + 5) / 2)
          )}
        </div>
      </div>
    );
  };

  const showselected_courses = () => {
    let resultCourseFroms = [];
    fullCourseTemplate.map(
      (dropdownForm, dropdownFormIndx, oldDropDownForm) => {
        let title = dropdownForm.title;
        switch (title) {
          case "Primary / Pre-Primary":
            title = "Primary / Pre-Primary / LKG / UKG";
            break;
          case "1st Class":
            title = "1st - 5th Classes";
            break;
          case "6th Class":
            title = "6th - 8th Classes";
            break;
          case " Special Kid ( 2 years to 4 years )":
            title = "Special Kid( ADHD/ADD/AUTISM )";
            break;
          default:
        }
        if (
          dropdownForm.selected_courses.length !== 0 &&
          ![
            "7th Class",
            "8th Class",
            "LKG / UKG",
            "2nd Class",
            "3rd Class",
            "4th Class",
            "5th Class",
            " Special Kid ( 5 years to 8 years )",
            " Special Kid ( 9 years to 12 years )",
            " Special Kid ( 13 years to 16 years )",
          ].includes(dropdownForm.title)
        )
          resultCourseFroms.push(
            <Form key={dropdownFormIndx} className="form--wrapper">
              <div className={`form-heading active-form-heading`}>{title}</div>
              <div className={`form-options--wrapper`}>
                {dropdownForm.selected_courses.map((course, index) => (
                  <Row key={index} className={`form-option`}>
                    <Col span={30}>
                      <Radio checked={true} onChange={() => {}}>
                        {course}
                      </Radio>
                    </Col>
                  </Row>
                ))}
              </div>
            </Form>
          );
        return dropdownForm;
      }
    );

    return (
      <div className={`all-forms-wrapper`}>
        <div className="left-forms-wrapper">
          {resultCourseFroms.slice(0, Math.ceil(resultCourseFroms.length / 2))}
        </div>
        <div className="right-forms-wrapper">
          {resultCourseFroms.slice(Math.ceil(resultCourseFroms.length / 2))}
        </div>
      </div>
    );
  };


  return (
    <main className="my-courses-component-wrapper">
      <div className="forms-with-btn-container">
        {step === 0 ? (
          <>
            <Space className="my-courses-component-btns-wrapper">
              <Button
                className="primary-btn"
                onClick={() => {
                  if (
                    fullCourseTemplate.find(
                      (dropdown) =>
                        [1, 4].includes(dropdown.category_id) &&
                        dropdown.selected_courses.length !== 0
                    )
                  )
                    setStep(1);
                  else setStep(2);
                }}
              >
                Next
              </Button>
            </Space>

            {returnCourses()}
          </>
        ) : step === 1 ? (
          <>
            <Space className="my-courses-component-btns-wrapper">
              <Button
                className="secondary-btn"
                onClick={() => {
                  setStep(0);
                }}
              >
                Previous
              </Button>
              <Button
                className="primary-btn"
                onClick={() => {
                  setStep(2);
                }}
              >
                Next
              </Button>
            </Space>

            <Form
              key={0}
              className={`form--wrapper`}
              style={{ margin: " 0 auto", width: "95%" }}
            >
              <div className={`form-heading active-form-heading`}>
                {fullCourseTemplate[0].title}
                <img src="./up-arrow-grey-icon.svg" alt="up-arrow-grey-icon" />
              </div>
              <div className={`form-options--wrapper`}>
                {fullCourseTemplate[0].src_courses.map((course, index) => (
                  <Row key={index} className={`form-option`}>
                    {/* <label style={{ cursor: "pointer" }}>
                      <input
                        type="checkbox"
                        checked={fullCourseTemplate[0].selected_courses.includes(
                          course
                        )}
                        onChange={() => {
                          handleCourseChange(course, 0, fullCourseTemplate);
                        }}
                      />
                      {course}
                    </label> */}
                    <Col span={30}>
                      <Checkbox
                        checked={fullCourseTemplate[0].selected_courses.includes(
                          course
                        )}
                        onChange={() => {
                          handleCourseChange(course, 0, fullCourseTemplate);
                        }}
                      >
                        {course}
                      </Checkbox>
                    </Col>
                  </Row>
                ))}
              </div>
            </Form>
          </>
        ) : step === 2 ? (
          <>
            <div className="my-courses-component-btns-wrapper">
              <div
                className="secondary-btn"
                onClick={() => {
                  if (
                    fullCourseTemplate.find(
                      (dropdown) =>
                        [1, 4].includes(dropdown.category_id) &&
                        dropdown.selected_courses.length !== 0
                    )
                  )
                    setStep(1);
                  else setStep(0);
                }}
              >
                Previous
              </div>
              <div
                className="primary-btn"
                onClick={() => {
                  saveTutorCourses();
                }}
              >
                Save
              </div>
            </div>
            {showselected_courses()}
          </>
        ) : (
          step === 3 && <>{showselected_courses()}</>
        )}
      </div>
    </main>
  );
}
