import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "../axios";
import { useAtom } from "jotai";
import { showNavigation } from "../states/showNavigation";
import {
  userRole,
  userTutorCityAccess,
  userStudentCityAccess,
  userShadowTutorCityAccess,
} from "../states/userRole";
import { jwtDecode } from "jwt-decode";

function Login() {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useAtom(showNavigation);
  const [role, setRole] = useAtom(userRole);
  const [tutorCityAccess, setTutorCityAccess] = useAtom(userTutorCityAccess);
  const [studentCityAccess, setStudentCityAccess] = useAtom(
    userStudentCityAccess
  );
  const [shadowTutorCityAccess, setShadowTutorCityAccess] = useAtom(
    userShadowTutorCityAccess
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("homeshiksha-cre-jwt")) {
      const decoded = jwtDecode(localStorage.getItem("homeshiksha-cre-jwt"));
      setRole(decoded.authorization.x_authorization_role);
      setTutorCityAccess(decoded.authorization.x_authorization_tutor_city.split(","));
      setStudentCityAccess(decoded.authorization.x_authorization_student_city.split(","));
      setShadowTutorCityAccess(
        decoded.authorization.x_authorization_shadow_tutor_city.split(",")
      );
      navigate("/tutors");
    }
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await axios.get("/v1/cre/login", {
        params: {
          email,
          password,
        },
      });
      const { is_success, data, error } = response.data;

      if (is_success) {
        localStorage.setItem("homeshiksha-cre-jwt", data);
        const decoded = jwtDecode(data);
        setRole(decoded.authorization.x_authorization_role);
        setTutorCityAccess(decoded.authorization.x_authorization_tutor_city.split(","));
        setStudentCityAccess(
          decoded.authorization.x_authorization_student_city.split(",")
        );
        setShadowTutorCityAccess(
          decoded.authorization.x_authorization_shadow_tutor_city.split(",")
        );
        notification.success({
          message: "Login Successful",
          description: "You have been logged in Successfully",
        });
        setShowMenu(true);
        navigate("/tutors");
      } else {
        notification.error({
          message: "Login Failed",
          description: error,
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed to Login",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "90vw", height: "90vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Form layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input a valid email",
            },
          ]}
        >
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "20px",

        }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
