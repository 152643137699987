import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import { Button, Layout, Flex } from "antd";
import Logo from "./components/Logo";
import { jwtDecode } from "jwt-decode";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import MenuList from "./components/MenuList";
import TutorTable from "./components/TutorTable";
import Team from "./Pages/Team";
import CreateCre from "./Pages/CreateCre";
import Login from "./Pages/login";
import axios from "./axios";
import { useAtom } from "jotai";
import FlashCards from './components/FlashCards'; 
import { citysList } from "./states/citys";
import {
  userRole,
  userTutorCityAccess,
  userStudentCityAccess,
  userShadowTutorCityAccess,
} from "./states/userRole";
import { showNavigation } from "./states/showNavigation";
import Student from "./Pages/Student";
import PrivateRoute from "./components/PrivateRoute";
import log from "./utility/logging";
const { Sider } = Layout;

function App() {
  const [collapsed, setCollapsed] = useState(true);
  const [showMenu, setShowMenu] = useAtom(showNavigation);
  const [tutorCityAccess, setTutorCityAccess] = useAtom(userTutorCityAccess);
  const [studentCityAccess, setStudentCityAccess] = useAtom(
    userStudentCityAccess
  );
  const [shadowTutorCityAccess, setShadowTutorCityAccess] = useAtom(
    userShadowTutorCityAccess
  );

  const [citys, setCitys] = useAtom(citysList);
  const [role, setRole] = useAtom(userRole);

  useEffect(() => {
    try {
      axios
        .get("/v1/cre/citys")
        .then((response) => {
          const citysList = response.data.data;
          setCitys(citysList);
          if (window.localStorage.getItem("homeshiksha-cre-jwt")) {
            const decoded = jwtDecode(
              localStorage.getItem("homeshiksha-cre-jwt")
            );
            setRole(decoded.authorization.x_authorization_role);
            setTutorCityAccess(
              decoded.authorization.x_authorization_tutor_city.split(",")
            );
            setStudentCityAccess(
              decoded.authorization.x_authorization_student_city.split(",")
            );
            setShadowTutorCityAccess(
              decoded.authorization.x_authorization_shadow_tutor_city.split(",")
            );
            setShowMenu(true);
          }
        })
        .catch((error) => {
          log.debug(error);
        });
    } catch (error) {
      log.debug(error);
    }
  }, []);

  return (
    <main>
      <Router>
        <Layout>
          <Sider
            className="siderbar"
            collapsed={collapsed}
            collapsible
            trigger={null}
          >
            <Flex justify="center" align="center">
              <NavLink to="/">
                <Logo />
              </NavLink>
            </Flex>
            {showMenu && (
              <>
                <div onClick={() => setCollapsed(false)}>
                  <MenuList />
                </div>
                <div className="sideNavToggle-wrapper">
                  <Button
                    type="text"
                    className="toggle"
                    onClick={() => setCollapsed(!collapsed)}
                    icon={
                      collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                    }
                  />
                </div>
              </>
            )}
          </Sider>
          <Layout
            className="main-layout"
            onClick={() => {
              setCollapsed(true);
            }}
          >
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/students" element={<Student />} />
              <Route path="/tutors" element={<TutorTable />} />
              <Route path="/create-cre" element={<CreateCre />} />
              <Route path="/cre-team" element={<Team />} />
              <Route path="/home" element={<FlashCards />} /> 
              <Route path="*" element={<>404</>} />
            </Routes>
          </Layout>
        </Layout>
      </Router>
    </main>
  );
}

export default App;
