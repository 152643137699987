import log from "loglevel";

// Set logging level based on the environment
if (process.env.NODE_ENV === "production") {
  log.setLevel("silent"); // Turn off all logging in production
} else {
  log.setLevel("debug"); // Enable debug logging in development
}

export default log;
