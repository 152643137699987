import { 
    FileTextOutlined, 
    CloseCircleOutlined, 
    BarChartOutlined, 
    ClockCircleOutlined, 
    TagOutlined, 
    LikeOutlined, 
    FileProtectOutlined, 
    ExclamationCircleOutlined 
  } from '@ant-design/icons';
  import dayjs from 'dayjs';
  
  export const COLOR_OPTIONS = ['#E4A76A', '#DB805D', '#CAD07C', '#79BCC2', '#315685', '#5641D1', '#FF5733', '#C70039', '#900C3F', '#FFC300'];
  
  export const DATE_RANGE_MAP = {
    'today': {
      fromDate: dayjs().startOf('day').format('YYYY-MM-DD'),
      toDate: dayjs().endOf('day').format('YYYY-MM-DD')
    },
    'yesterday': {
      fromDate: dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
      toDate: dayjs().subtract(1, 'day').endOf('day').format('YYYY-MM-DD')
    },
    'this_week': {
      fromDate: dayjs().startOf('week').format('YYYY-MM-DD'),
      toDate: dayjs().endOf('week').format('YYYY-MM-DD')
    },
    'last_week': {
      fromDate: dayjs().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'),
      toDate: dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
    },
    'this_month': {
      fromDate: dayjs().startOf('month').format('YYYY-MM-DD'),
      toDate: dayjs().endOf('month').format('YYYY-MM-DD')
    },
    'last_month': {
      fromDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      toDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    }
  };
  
  export const STATUS_DISPLAY_NAME = {
    PENDING: 'Pending',
    DEMO: 'Demo',
    ASSIGNED: 'Assigned',
    CONFIRMED: 'Confirmed',
    NEW: 'New',
    IN_PROGRESS: 'In Progress',
    ESCALATE: 'Escalate',
    INACTIVE: 'Inactive',
    PROSPECT: 'Prospect',
    NOT_INTERESTED: 'Not Interested',
    DOC_DONE: 'Doc Done',
    INTERESTED: 'Interested',
    PAYMENT_DONE: 'Payment Done',
    VERIFIED: 'Verified',
    ACTIVE: 'Active',
    BLOCKED: 'Blocked',
    SHADOW_TEACHERS: 'Shadow Teachers'
  };
  
  export const STUDENT_STATUS = {
    PENDING: 'pending',
    DEMO: 'demo',
    ASSIGNED: 'assigned',
    CONFIRMED: 'confirmed',
    NEW: 'new',
    IN_PROGRESS: 'in_progress',
    ESCALATE: 'escalate',
    INACTIVE: 'inactive',
    PROSPECT: 'prospect',
    NOT_INTERESTED: 'Not_interested'
  };
  
  export const TUTOR_STATUS = {
    NEW: 'new',
    DOC_DONE: 'doc_done',
    INTERESTED: 'interested',
    NOT_INTERESTED: 'not_interested',
    PAYMENT_DONE: 'payment_done',
    VERIFIED: 'verified',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    BLOCKED: 'blocked',
    SHADOW_TEACHERS: 'shadow_teachers'
  };
  
  export const LEAD_TYPE = {
    STUDENT: 0,
    TUTOR: 1
  };
  
  export const STATUS_ICONS = {
    'New': <FileTextOutlined />,
    'Not Interested': <CloseCircleOutlined />,
    'Prospect': <BarChartOutlined />,
    'In Progress': <ClockCircleOutlined />,
    'Assigned': <TagOutlined />,
    'Pending': <LikeOutlined />,
    'Confirmed': <FileProtectOutlined />,
    'Escalate': <ExclamationCircleOutlined />,
    'Doc Done': <BarChartOutlined />,
    'Interested': <TagOutlined />,
    'Payment Done': <LikeOutlined />,
    'Verified': <FileProtectOutlined />,
    'Active': <FileProtectOutlined />,
    'Inactive': <FileProtectOutlined />,
    'Blocked': <FileProtectOutlined />,
    'Shadow Teachers': <FileProtectOutlined />,
  };

  export const DATE_FILTER_TYPE = {
    relative: 'relative',
    absolute: 'absolute'
  };

  export const getDateRange = (day) =>{
    return day.endOf("day").format("YYYY-MM-DD");
  }