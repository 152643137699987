import React, { useState, useEffect } from 'react';
import { Switch, Select, Button } from 'antd';
import axios from 'axios';
import './escalationSwitch.css';
import log from '../logger'; 

const { Option } = Select;

const EscalationSwitch = ({ checked, onChange, selectedExecutive, onExecutiveChange, onEscalate }) => {
  const [executives, setExecutives] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('YOUR_API_ENDPOINT')
      .then(response => {
        setExecutives(response.data);
        setLoading(false);
      })
      .catch(error => {
        log.error('Error fetching executives:', { error });
        setLoading(false);
      });
  }, []);

  return (
    <div className="container">
      <div className="item">
        <label className="label">Escalation Switch:</label>
        <Switch checked={checked} onChange={onChange} />
      </div>
      <div className="item">
        <Select 
          value={selectedExecutive}
          onChange={onExecutiveChange}
          placeholder="Select Executive"
          style={{ width: 160 }}
          loading={loading}
        >
          {executives.map(executive => (
            <Option key={executive.id} value={executive.id}>
              {executive.name}
            </Option>
          ))}
        </Select>
      </div>
      <Button type="primary" onClick={onEscalate}>
        Escalate
      </Button>
    </div>
  );
};

export default EscalationSwitch;
