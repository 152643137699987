import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  HomeOutlined,
  LogoutOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  IdcardOutlined, 
  ReadOutlined,   
} from '@ant-design/icons';

import './MenuList.css';
import { useAtom } from 'jotai';
import { userRole } from '../states/userRole';
import { showNavigation } from '../states/showNavigation';
import React from 'react';

export default function MenuList() {
  const [showNav, setShowNav] = useAtom(showNavigation);
  const [role, setRole] = useAtom(userRole);

  return (
    <Menu theme="dark" mode="inline" className="menu-bar">
      <Menu.Item key="dashboard" icon={<HomeOutlined />}>
        <NavLink to="/home">Dashboard</NavLink>
      </Menu.Item>
      <Menu.Item key="tutors" icon={<IdcardOutlined />}>
        <NavLink to="/tutors">Tutors</NavLink>
      </Menu.Item>
      <Menu.Item key="parents-students" icon={<ReadOutlined />}>
        <NavLink to="/students">Parents/Students</NavLink>
      </Menu.Item>
      {(role === 'Superadmin' || role === 'Admin') && (
        <Menu.SubMenu key="subnav1" title="Employees" icon={<TeamOutlined />}>
          <Menu.Item key="cre-team" icon={<TeamOutlined />}>
            <NavLink to="/cre-team">Team</NavLink>
          </Menu.Item>
          <Menu.Item key="create-cre" icon={<UsergroupAddOutlined />}>
            <NavLink to="/create-cre">Create CRE</NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      )}
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        <NavLink
          to="/"
          onClick={() => {
            localStorage.removeItem('homeshiksha-cre-jwt');
            setShowNav(false);
          }}
        >
          LogOut
        </NavLink>
      </Menu.Item>
    </Menu>
  );
}
