import React, { useState, useEffect } from "react";
import { Card, Switch, Row, Col, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import axiosInstance from "../axios.js";
import {
  COLOR_OPTIONS,
  DATE_RANGE_MAP,
  STATUS_DISPLAY_NAME,
  STUDENT_STATUS,
  TUTOR_STATUS,
  LEAD_TYPE,
  STATUS_ICONS,
} from "./constants";
import "./FlashCards.css";
import { useAtom } from "jotai";
import { citysList } from "../states/citys";
import { userRole, userTutorCityAccess, userStudentCityAccess } from "../states/userRole";
import { USER_ROLES } from "../constants/roleConstants.js";

const { Option } = Select;
const { RangePicker } = DatePicker;

const FlashCards = () => {
  const [isTutors, setIsTutors] = useState(false);
  const [flashcards, setFlashcards] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [presetRange, setPresetRange] = useState("today");
  const [dateRangeType, setDateRangeType] = useState("relative");
  const [error, setError] = useState(null);
  const [cities] = useAtom(citysList);
  const [role] = useAtom(userRole);
  const [tutorCityAccess] = useAtom(userTutorCityAccess);
  const [studentCityAccess] = useAtom(userStudentCityAccess);

  const defaultStudentCities = role !== USER_ROLES.SUPERADMIN
    ? studentCityAccess?.map((city_id) => city_id)
    : Object.keys(cities).map((city_id) => city_id);
  const defaultTutorCities = role !== USER_ROLES.SUPERADMIN
    ? tutorCityAccess?.map((city_id) => city_id)
    : Object.keys(cities).map((city_id) => city_id);
  const [selectedCities, setSelectedCities] = useState(isTutors ? defaultStudentCities : defaultTutorCities);

  useEffect(() => {
    fetchFlashcards();
  }, [isTutors, dateRange, presetRange, dateRangeType, selectedCities]);

  const fetchFlashcards = async () => {
    try {
      let fromDate = "",
        toDate = "";

      if (
        dateRangeType === "relative" &&
        presetRange &&
        DATE_RANGE_MAP[presetRange]
      ) {
        fromDate = DATE_RANGE_MAP[presetRange].fromDate;
        toDate = DATE_RANGE_MAP[presetRange].toDate;
      } else if (dateRangeType === "absolute" && dateRange.length === 2) {
        const [start, end] = dateRange;
        if (start.isAfter(end) || start.isSame(end)) {
          setError("Start date cannot be same or after end date.");
          return;
        }
        fromDate = start.startOf("day").format("YYYY-MM-DD");
        toDate = end.endOf("day").format("YYYY-MM-DD");
      }

      let cities = selectedCities.join(",");

      const statuses = isTutors
        ? Object.values(TUTOR_STATUS)
        : Object.values(STUDENT_STATUS);
      const leadType = isTutors ? LEAD_TYPE.TUTOR : LEAD_TYPE.STUDENT;

      const cards = await Promise.all(
        statuses.map(async (status) => {
          const timestamp = new Date().getTime();
          const url = "/v1/cre/get-new-leads";

          try {
            const response = await axiosInstance.get(url, {
              params: { status, leadType, fromDate, toDate, city: cities, _: timestamp },
            });

            const fetchedData = response.data.data;
            const currentPath = window.location.href.includes(".com")
              ? window.location.href.split(".com")[0] + ".com"
              : window.location.origin;

            const params = new URLSearchParams();
            isTutors
              ? params.append("filter[tutor_status][0]", status)
              : params.append("filter[status][0]", status);
            params.append("filter[searchFieldType]", "first_name");
            params.append("filter[fromDate]", fromDate);
            params.append("filter[toDate]", toDate);
            params.append("filter[page]", "1");
            params.append("filter[limit]", "10");
            params.append("page", "1");
            params.append("limit", "10");
            params.append("filter[city]", cities);

            return {
              id: status,
              value: STATUS_DISPLAY_NAME[status.toUpperCase()] || status,
              details: fetchedData.count.toString(),
              icon:
                STATUS_ICONS[
                STATUS_DISPLAY_NAME[status.toUpperCase()] || status
                ] || STATUS_ICONS["New"],
              link: `${currentPath}/${isTutors ? "tutors" : "students"
                }?${params.toString()}`,
              color:
                COLOR_OPTIONS[statuses.indexOf(status) % COLOR_OPTIONS.length],
            };
          } catch (error) {
            console.error(`Error fetching data for status: ${status}`, error);
            throw error;
          }
        })
      );
      console.log("Cards ", cards);
      setFlashcards(cards);
      setError(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data");
      setFlashcards([]);
    }
  };

  const handleToggleChange = (checked) => setIsTutors(checked);

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      if (start.isAfter(end) || start.isSame(end)) {
        setError("Start date cannot be same or after end date.");
      } else {
        setDateRange(dates);
        setPresetRange("");
        setError(null);
      }
    } else {
      setDateRange([]);
      setPresetRange("");
    }
  };

  const handlePresetRangeChange = (value) => {
    setPresetRange(value);
    setDateRange([]);
  };

  const handleDateRangeTypeChange = (checked) => {
    setDateRangeType(checked ? "absolute" : "relative");
    setDateRange([]);
    setPresetRange("today");
  };

  const handleCityMulriSelect = (value) => {
    if (value.length === 0) {
      return; //Atleast one city should be selected
    }
    setSelectedCities(value);
  };

  return (
    <div className="flash-cards-container">
      <div className="controls-container">
        <Switch
          className="toggle"
          checkedChildren="Tutors"
          unCheckedChildren="Students"
          checked={isTutors}
          onChange={handleToggleChange}
        />
        <Switch
          className="toggle"
          checkedChildren="Absolute"
          unCheckedChildren="Relative"
          checked={dateRangeType === "absolute"}
          onChange={handleDateRangeTypeChange}
        />
        <div className="date-controls">
          {dateRangeType === "absolute" ? (
            <RangePicker
              className="date-range-picker"
              value={dateRange}
              onChange={handleDateChange}
              allowClear={false}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          ) : (
            <Select
              className="preset-range-select"
              value={presetRange}
              onChange={handlePresetRangeChange}
            >
              <Option value="today">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="this_week">This Week</Option>
              <Option value="last_week">Last Week</Option>
              <Option value="this_month">This Month</Option>
              <Option value="last_month">Last Month</Option>
            </Select>
          )}
        </div>
        <div className="city-controls">
          <span>City:</span>
          <Select
            className="city-select"
            mode="multiple"
            value={selectedCities}
            onChange={handleCityMulriSelect}
            placeholder="Select cities"
            maxTagCount={3}
            maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} selected`}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {isTutors && defaultTutorCities &&
              defaultTutorCities.map((city_id) => (
                <Option key={city_id}>{cities[city_id]}</Option>
              ))}
            {!isTutors && defaultStudentCities &&
              defaultStudentCities.map((city_id) => (
                <Option key={city_id}>{cities[city_id]}</Option>
              ))}
          </Select>
        </div>
      </div>
      {error && <div className="error-message">{error}</div>}
      <Row gutter={[16, 16]}>
        {flashcards.map((card) => (
          <Col key={card.id} xs={24} sm={12} md={8} lg={8} xl={8}>
            <Card
              className="card"
              style={{ backgroundColor: card.color }}
              title={
                <div className="card-title">
                  {React.cloneElement(card.icon, { className: "card-icon" })}
                  <span className="card-value">{card.value}</span>
                </div>
              }
              bordered={false}
              extra={
                <a href={card.link} className="card-link">
                  Details
                </a>
              }
            >
              <div className="card-content">
                <div className="card-details">
                  <p className="card-details-number">{card.details}</p>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FlashCards;
